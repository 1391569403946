import PropTypes from 'prop-types';

/* eslint-disable i18next/no-literal-string */

export const Star = ({ fill }) => (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 0L13.588 6.25017L20.5 7.26123L15.4934 12.1327L16.6761 19L10.5 15.7567L4.32392 19L5.50657 12.1327L0.5 7.26123L7.41196 6.25017L10.5 0Z"
      fill={fill}
    />
  </svg>
);

Star.propTypes = {
  fill: PropTypes.string,
};

Star.defaultProps = {
  fill: '#FF492C',
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const Title = styled.h1`
  text-align: center;
  max-width: 880px;
  font-size: 60px;
  font-weight: 700;
  margin: 0;
  color: #242424;

  @media (max-width: 768px) {
    font-size: 48px;
  }

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    font-size: 36px;
  }
`;

import styled from 'styled-components';

export const Intro = styled.div`
  max-width: 422px;
  margin-right: 41px;

  @media (max-width: 960px) {
    display: contents;
  }
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 42px;
  line-height: 49px;
  color: #2f2f2f;
  margin: 0px 0px 32px;
  order: 1;

  @media (max-width: 600px) {
    font-size: 32px;
    margin-bottom: 52px;
    width: 100%;
    line-height: 1.2;
  }
`;

export const Content = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #767676;
  margin-bottom: 60px;
  order: 2;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Image = styled.img`
  max-width: 100%;

  @media (max-width: 960px) {
    order: 4;
    margin: 60px auto 0;
  }
`;

import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
  max-width: 526px;
  min-width: 480px;
  width: 100%;
  height: fit-content;
  background: #ffffff;
  border-radius: 16px;
  padding: 40px;

  @media (max-width: 1060px) {
    padding: 30px;
  }

  @media (max-width: 960px) {
    margin: 0px auto;
    min-width: auto;
  }

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

export const Disclaimer = styled.div`
  margin-top: 15px;
  color: #9d9d9d;
  font-size: 12px;
`;

export const PrivacyNotice = styled.div`
  font-size: 12px;
  color: #9d9d9d;
  line-height: 16px;
  text-align: left;
  max-width: 367px;
  margin-top: 15px;
`;

export const Link = styled.a`
  color: #9d9d9d;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid #9d9d9d;
`;

export const Button = styled.button`
  color: #fff;
  background-color: #47b972;
  padding: 12px 20px;
  margin: 10px auto auto;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 19px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.25s;
  width: 100%;
  height: 46px;

  &:hover {
    background-color: #43af6c;
  }
`;

export const Title = styled.div`
  color: #242424;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 28px;
  text-align: center;
  width: 100%;
`;

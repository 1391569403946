import React from 'react';
import { Layout } from 'components/Layout';
import { Header } from 'components/Header';
import { Intro } from './components/Intro';
import { Form } from './components/Form';
import { UserStories } from './components/UserStories';
import { Footer } from './components/Footer';
import * as Styled from './styled';

export const RequestApiDemo = () => (
  <Layout background="#f5f1ee">
    <Header />
    <Styled.FormWrapper>
      <Intro />
      <Form />
    </Styled.FormWrapper>
    <UserStories />
    <Footer />
  </Layout>
);

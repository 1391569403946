import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { MAP_FROM_CODE_TO_COUNTRY } from 'libjs-pdcomponents/fields/PhoneField/constants';
import { useEffect, useState } from 'react';

export const usePhoneCountryLocalization = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [localizationData, setLocalizationData] = useState({
    forceRerenderKey: 1,
    localization: {},
  });

  useEffect(() => {
    // After changing the language, the country names in the drop-down
    // list must be updated to the correct language
    const localization = {};

    Object.entries(MAP_FROM_CODE_TO_COUNTRY).forEach(([code, country]) => {
      localization[code] = t(country);
    });

    setLocalizationData((prevState) => ({
      localization,
      forceRerenderKey: prevState.forceRerenderKey + 1,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return localizationData;
};

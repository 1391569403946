import React from 'react';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import * as Styled from './styled';
import { STORIES } from './constants';

export const UserStories = () => {
  const { t } = useTranslation();

  return (
    <Styled.UserStories>
      {STORIES.map((story) => (
        <Styled.StoryCard key={story.key}>
          <div>
            <Styled.Logo logo={story.logo} />
            <Styled.Content>{t(story.content)}</Styled.Content>
          </div>
          <Styled.Person>
            <Styled.Avatar avatar={story.person.avatar} />
            <div>
              <Styled.Name>{story.person.name}</Styled.Name>
              <Styled.Title>{t(story.person.title)}</Styled.Title>
            </div>
          </Styled.Person>
        </Styled.StoryCard>
      ))}
    </Styled.UserStories>
  );
};

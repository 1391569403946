import { getEnvironment } from './utils/getEnvironment';

const commonProperties = {
  RAVEN_URL:
    'https://45e1164221f24b309ffdc20a3b78c48d@sentry.infrastructure.pandadoc.com/121',
  SENTRY_PROJECT_KEY: 'request-demo-web',
  ENVIRONMENT: getEnvironment(),
  SIGNUP_BASE_URL: 'https://signup.pandadoc.com',
};

const getDevelopmentConfig = () => ({
  ...commonProperties,
  API_BASE_URL: 'http://0.0.0.0:3000',
  IS_SEGMENTIO_ENABLED: false,
  SEGMENTIO_KEY: 'shtMPWEV1r4lPg9Latl7BrbKW7ghmwvh',
  IS_CHILI_PIPER_ENABLED: true,
  JITSU_ENABLED: false,
  JITSU_AUTH_KEY: '',
  JITSU_HOST: '',
  JITSU_COOKIE_DOMAIN: '',
  IS_DRIFT_ENABLED: false,
});

const getProducationConfig = () => ({
  ...commonProperties,
  API_BASE_URL: window.location.origin + '/api',
  IS_SEGMENTIO_ENABLED: true,
  SEGMENTIO_KEY: 'IN9wKPxg93hx85atsQFJxStKZWxpOfRU',
  IS_CHILI_PIPER_ENABLED: true,
  JITSU_AUTH_KEY: '71d3dc53-18cb-4828-bef4-b49884b2d5a2',
  JITSU_COOKIE_DOMAIN: 'pandadoc.com',
  JITSU_ENABLED: true,
  JITSU_HOST: 'https://track.pandadoc.com',
  IS_DRIFT_ENABLED: true,
});

export const appConfig =
  process.env.NODE_ENV === 'development'
    ? getDevelopmentConfig()
    : getProducationConfig();

import React from 'react';
import { useSelector } from 'store';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { activeLanguageSelector } from 'containers/Application/selectors';
import * as Styled from './styled';
import linkedinIcon from './images/linkedin.svg';
import facebookIcon from './images/facebook.svg';
import twitterIcon from './images/twitter.svg';
import instagramIcon from './images/instagram.svg';
import youtubeIcon from './images/youtube.svg';

export const Footer = () => {
  const { t } = useTranslation();
  const year = 2023;

  const activeLanguage = useSelector(activeLanguageSelector);

  return (
    <Styled.Footer>
      <Styled.Socials>
        <Styled.SocialLink
          href="https://www.linkedin.com/company/pandadoc/"
          target="_blank"
          rel="noreferrer"
          icon={linkedinIcon}
        />
        <Styled.SocialLink
          href="https://www.facebook.com/PandaDoc/"
          target="_blank"
          rel="noreferrer"
          icon={facebookIcon}
        />
        <Styled.SocialLink
          href="https://twitter.com/pandadoc"
          target="_blank"
          rel="noreferrer"
          icon={twitterIcon}
        />
        <Styled.SocialLink
          href="https://www.instagram.com/pandadochq/"
          target="_blank"
          rel="noreferrer"
          icon={instagramIcon}
        />
        <Styled.SocialLink
          href="https://www.youtube.com/user/thePandaDoc"
          target="_blank"
          rel="noreferrer"
          icon={youtubeIcon}
        />
      </Styled.Socials>
      <Styled.Flex>
        <Styled.Rights>
          {t('© {{year}} PandaDoc Inc. All rights reserved.', { year })}
        </Styled.Rights>
        <div>
          <Styled.Link
            href={`https://www.pandadoc.com${activeLanguage.path}/privacy-notice/`}
          >
            {t('Privacy notice')}
          </Styled.Link>
          <Styled.Link
            href={`https://www.pandadoc.com${activeLanguage.path}/terms-of-use/`}
          >
            {t('Terms of use')}
          </Styled.Link>
          <Styled.Link
            href={`https://www.pandadoc.com${activeLanguage.path}/cookie-notice/`}
          >
            {t('Cookie notice')}
          </Styled.Link>
          <Styled.Link href={`https://www.pandadoc.com${activeLanguage.path}/gdpr/`}>
            {t('GDPR')}
          </Styled.Link>
        </div>
      </Styled.Flex>
    </Styled.Footer>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { fieldPropTypes } from 'containers/Application/model/fieldPropTypes';
import { FieldWrapper, Label, ActiveLabel, FieldError } from '../common';
import * as Styled from './styled';

export const TextField = ({
  fieldModel: { id, value, error },
  label,
  setFieldValue,
  validateField,
  type,
  autoFocus,
  fullWidth,
  ...props
}) => {
  const { t } = useTranslation();

  const onChange = (event) => {
    setFieldValue({
      id,
      value: event.target.value,
    });
  };

  const onBlur = () => {
    validateField({ id });
  };

  return (
    <FieldWrapper fullWidth={fullWidth} {...props}>
      <Styled.Input
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        value={value}
        id={id}
        name={id}
        error={error}
        autoFocus={autoFocus}
      />
      {value ? <ActiveLabel>{t(label)}</ActiveLabel> : <Label>{t(label)}</Label>}
      {error && <FieldError>{t(error)}</FieldError>}
    </FieldWrapper>
  );
};

TextField.propTypes = {
  fieldModel: PropTypes.shape(fieldPropTypes).isRequired,
  label: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

TextField.defaultProps = {
  type: 'text',
  autoFocus: false,
  fullWidth: false,
};

import styled from 'styled-components';

export const Footer = styled.div`
  color: #888;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  padding: 40px 0;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div:first-child {
      margin-bottom: 20px;
    }
  }
`;

export const Link = styled.a`
  color: #666;
  text-decoration: none;
  padding-left: 20px;

  &:hover {
    color: #242424;
  }

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    padding: 10px;
  }
`;

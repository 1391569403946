import { PrevArrowWrapper } from '../styled';
import React from 'react';
import { PrevSlideIcon } from './PrevSlideIcon';

export function PrevArrow(props) {
  return (
    <PrevArrowWrapper {...props}>
      <PrevSlideIcon fill="#242424" />
    </PrevArrowWrapper>
  );
}

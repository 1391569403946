import styled from 'styled-components';

export const Form = styled.form`
  width: 490px;
  min-width: 420px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    order: 3;
  }

  @media (max-width: 600px) {
    width: 100%;
    min-width: auto;
  }
`;

export const Button = styled.button`
  color: #fff;
  background-color: #47b972;
  padding: 12px 20px;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.25s;

  &:hover {
    background-color: #43af6c;
  }
`;

class API {
  constructor() {
    this.backend = {};
    this.backendUrl = '/';
  }

  init = ({ backendUrl }) => {
    this.backendUrl = backendUrl;
  };

  requestDemo = ({ fields, form }) =>
    window
      .fetch(`${this.backendUrl}/request`, {
        method: 'POST',
        body: JSON.stringify({ fields, form }),
      })
      .then((res) => {
        return res.json();
      });

  validateEmail = ({ email }) =>
    window
      .fetch(`https://forms.hsforms.com/emailcheck/v1/json-ext?portalId=2127247`, {
        method: 'POST',
        body: email,
      })
      .then((res) => {
        return res.json();
      });

  getCountryCode = () =>
    window
      .fetch(`${this.backendUrl}/country`)
      .then((res) => res.json())
      .catch(() => ({}));
}

const APIService = new API();

export { APIService };

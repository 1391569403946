import styled from 'styled-components';

export const UserStories = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  margin-bottom: 80px;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    justify-content: center;
    margin: 35px -15px;
  }
`;

export const StoryCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: #fff;
  border: 1px solid rgba(36, 36, 36, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
  justify-content: space-between;
  width: calc(33.3% - 20px);
  max-width: 340px;

  @media (max-width: 960px) {
    width: calc(50% - 30px);
    margin: 15px;
    min-height: 400px;
  }

  @media (max-width: 600px) {
    width: 100%;
    max-width: 360px;
    min-height: auto;
  }
`;

export const Logo = styled.div`
  width: 100%;
  height: 40px;
  background-image: url(${({ logo }) => logo});
  background-repeat: no-repeat;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

export const Content = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #242424;
  margin-bottom: 36px;

  @media (max-width: 600px) {
    font-size: 16px;
    margin-bottom: 2em;
  }
`;

export const Person = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-image: url(${({ avatar }) => avatar});
  background-size: contain;
  border-radius: 50%;
  margin-right: 10px;
`;

export const Name = styled.div`
  color: #242424;
  font-weight: 600;
  line-height: 1.2;
  font-size: 14px;
`;

export const Title = styled.div`
  color: #888;
  font-size: 12px;
  line-height: 1.2;
`;

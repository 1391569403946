import styled from 'styled-components';

export const Background = styled.div`
  width: 100%;
  background: #fff;
`;

export const SliderContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
`;

import { defineMessage } from '@libjs-pdlocalization/components/utils';

export const STORE_KEY = 'application';

export const DEFAULT_COUNTRY_CODE = 'US';

export const FIELDS = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  EMAIL_FOR_SSD: 'enail_for_ssd',
  PHONE: 'phone',
  COMPANY_NAME: 'company_name',
  COMPANY_SIZE: 'company_size',
  PREFERRED_LANGUAGE: 'preferred_language',
};

export const NOT_BUSINESS_EMAIL_ERROR = defineMessage('Please enter your business email');

export const NOT_VALID_EMAIL_ERROR = defineMessage('Please enter a valid email address');

export const TRACK_REQUEST_DEMO = {
  demo_requested: 'requested a demo',
  submission_attempted: 'Demo LP – Form submission attempted',
  error_appeared: 'Demo LP – Submission Error appeared',
};

export const PREFERRED_LANGUAGE_ANSWERS = [
  {
    value: 'English',
    label: defineMessage('English'),
  },
  {
    value: 'Dutch',
    label: defineMessage('Dutch'),
  },
  {
    value: 'French',
    label: defineMessage('French'),
  },
  {
    value: 'German',
    label: defineMessage('German'),
  },
  {
    value: 'Polish',
    label: defineMessage('Polish'),
  },
  {
    value: 'Portuguese',
    label: defineMessage('Portuguese'),
  },
  {
    value: 'Spanish',
    label: defineMessage('Spanish'),
  },
  {
    value: 'Swedish',
    label: defineMessage('Swedish'),
  },
];

export const COMPANY_SIZES = [
  {
    value: '1',
    label: defineMessage('Solo'),
  },
  {
    value: '2-10',
    label: '2\u002D10',
  },
  {
    value: '11-50',
    label: '11\u002D50',
  },
  {
    value: '51-200',
    label: '51\u002D200',
  },
  {
    value: '201-500',
    label: '201\u002D500',
  },
  {
    value: '501-1000',
    label: '501\u002D1000',
  },
  {
    value: '1001+',
    label: '1001+',
  },
];

export const MAP_ERRORS_TYPE_TO_FIELD = {
  INVALID_EMAIL: {
    id: FIELDS.EMAIL,
    error: defineMessage('Please enter a valid email address'),
  },

  BLOCKED_EMAIL: {
    id: FIELDS.EMAIL,
    error: defineMessage('Submission from this email address are not allowed'),
  },
};

import React from 'react';
import { connectToStore } from 'store';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { FIELDS } from 'containers/Application/constants';
import {
  firstNameFieldSelector,
  lastNameFieldSelector,
  workEmailFieldSelector,
  phoneNumberFieldSelector,
  companyNameFieldSelector,
  companySizeFieldSelector,
  countryCodeSelector,
  isEnglishActiveLanguageSelector,
} from 'containers/Application/selectors';
import {
  setFieldValue as setFieldValueAction,
  validateField as validateFieldAction,
  submitDemo as submitDemoAction,
} from 'containers/Application/actions';
import { ChiliPiperService } from 'services/ChiliPiperService';
import { fieldPropTypes } from 'containers/Application/model/fieldPropTypes';
import { API_DEMO_HUBSPOT_FORM } from 'services/HubSpotService/constants';
import { API_ROUTER } from 'services/ChiliPiperService/constants';
import * as Styled from './styled';
import { TextField } from './Fields/TextField';
import { PhoneField } from './Fields/PhoneField';
import { SelectField } from './Fields/SelectField';

const defaultFields = [
  FIELDS.FIRST_NAME,
  FIELDS.LAST_NAME,
  FIELDS.EMAIL,
  FIELDS.PHONE,
  FIELDS.COMPANY_NAME,
  FIELDS.COMPANY_SIZE,
];

const FormContainer = ({
  firstNameField,
  lastNameField,
  workEmailField,
  phoneNumberField,
  companyNameField,
  companySizeField,
  setFieldValue,
  validateField,
  submitDemo,
  countryCode,
  isEnglishActiveLanguage,
}) => {
  const { t } = useTranslation();

  const submitForm = (e) => {
    e.preventDefault();

    const isShowChiliPiperCalendar = ChiliPiperService.isShowChiliPiperCalendar({
      companySizeRange: companySizeField.value,
      isEnglishActiveLanguage,
    });

    submitDemo({
      fieldIds: defaultFields,
      hubSpotFrom: API_DEMO_HUBSPOT_FORM,
      isShowChiliPiperCalendar,
      router: API_ROUTER,
    });
  };

  return (
    <Styled.Form className="fcidstform" autoComplete="off" noValidate>
      <TextField
        label={`${t('First name')}*`}
        fieldModel={firstNameField}
        setFieldValue={setFieldValue}
        validateField={validateField}
      />
      <TextField
        label={`${t('Last name')}*`}
        fieldModel={lastNameField}
        setFieldValue={setFieldValue}
        validateField={validateField}
      />
      <TextField
        label={`${t('Work email')}*`}
        fieldModel={workEmailField}
        setFieldValue={setFieldValue}
        validateField={validateField}
        type="email"
      />
      <PhoneField
        label={`${t('Phone number')}*`}
        fieldModel={phoneNumberField}
        setFieldValue={setFieldValue}
        validateField={validateField}
        countryCode={countryCode}
      />
      <TextField
        label={`${t('Company name')}*`}
        fieldModel={companyNameField}
        setFieldValue={setFieldValue}
        validateField={validateField}
      />
      <SelectField
        label={`${t('Company size')}*`}
        fieldModel={companySizeField}
        setFieldValue={setFieldValue}
        validateField={validateField}
      />
      <Styled.Button onClick={submitForm}>{t('Schedule a session')}</Styled.Button>
    </Styled.Form>
  );
};

FormContainer.propTypes = {
  firstNameField: PropTypes.shape(fieldPropTypes).isRequired,
  lastNameField: PropTypes.shape(fieldPropTypes).isRequired,
  workEmailField: PropTypes.shape(fieldPropTypes).isRequired,
  phoneNumberField: PropTypes.shape(fieldPropTypes).isRequired,
  companyNameField: PropTypes.shape(fieldPropTypes).isRequired,
  companySizeField: PropTypes.shape(fieldPropTypes).isRequired,
  validateField: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitDemo: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  isEnglishActiveLanguage: PropTypes.bool.isRequired,
};

export const Form = connectToStore(
  createStructuredSelector({
    firstNameField: firstNameFieldSelector,
    lastNameField: lastNameFieldSelector,
    workEmailField: workEmailFieldSelector,
    phoneNumberField: phoneNumberFieldSelector,
    companyNameField: companyNameFieldSelector,
    companySizeField: companySizeFieldSelector,
    countryCode: countryCodeSelector,
    isEnglishActiveLanguage: isEnglishActiveLanguageSelector,
  }),
  {
    setFieldValue: setFieldValueAction,
    validateField: validateFieldAction,
    submitDemo: submitDemoAction,
  },
)(FormContainer);

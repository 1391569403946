/* eslint-disable i18next/no-literal-string */
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Graphik';
    src:
      url(/fonts/Graphik-Regular-Cy-Web.woff2) format('woff2'),
      url(/fonts/Graphik-Regular-Cy-Web.woff) format('woff');
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'Graphik';
    src:
      url(/fonts/Graphik-Semibold-Cy-Web.woff2) format('woff2'),
      url(/fonts/Graphik-Semibold-Cy-Web.woff) format('woff');
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: 'Graphik';
    src:
      url(/fonts/Graphik-Bold-Cy-Web.woff2) format('woff2'),
      url(f/onts/Graphik-Bold-Cy-Web.woff) format('woff');
    font-weight: 700;
    font-display: swap;
  }

  html {
    height: 100%;
  }

  body {
    height: 100%;
    background-color: transparent;
    select::-ms-expand {
      display: none;
    }
    input::-webkit-input-placeholder {
      color: rgba(0,0,0,0.35);
     }
     input:-moz-placeholder {
      color: rgba(0,0,0,0.35);
     }
     input::-moz-placeholder {
      color: rgba(0,0,0,0.35);
     }
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
    margin: 0px;
    font-family: Graphik, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }
`;

import React from 'react';
import { Intro } from './components/Intro';
import { Form } from './components/Form';
import { FormWrapper } from './components/Form/styled';
import { Clients } from './components/Clients';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Block } from './components/Block';
import { DemoInfo } from './components/DemoInfo';
import { Testimonials } from './components/Testimonials';
import { Reviews } from './components/Reviews';
import * as Styled from './styled';

export const RequestProductDemoExperiment = () => {
  return (
    <>
      <Block background="#EEE8FF">
        <Header />
        <Intro />
        <FormWrapper>
          <Form />
          <DemoInfo />
        </FormWrapper>
        <Styled.MobileReviewWrapper>
          <Reviews />
        </Styled.MobileReviewWrapper>
      </Block>
      <Block background="#FFF">
        <Clients />
      </Block>
      <Testimonials />
      <Block background="#FFF">
        <Footer />
      </Block>
    </>
  );
};

import { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

export const useSlider = ({ settings, numberOfSlides }) => {
  const [slideWidth, setSlideWidth] = useState(0);
  const [trackOffset, setTrackOffset] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const { ref: viewportRef, width: viewportWidth = 1 } = useResizeObserver();

  const isFirstSlide = activeSlideIndex === 0;
  const isLastSlide = activeSlideIndex === numberOfSlides - 1;

  // calculate slide width
  useEffect(() => {
    let slideWidth = viewportWidth;

    setTrackOffset(activeSlideIndex === 0 ? 0 : -slideWidth * activeSlideIndex);
    setSlideWidth(slideWidth);
  }, [viewportWidth]);

  const showNextSlide = () => {
    if (isLastSlide) return;
    setTrackOffset((offset) => {
      if (settings.slidesGap) {
        return offset - slideWidth - settings.slidesGap;
      }

      return offset - slideWidth;
    });
    setActiveSlideIndex((index) => index + 1);
  };

  const showPrevSlide = () => {
    if (isFirstSlide) return;
    setTrackOffset((offset) => {
      if (settings.slidesGap) {
        return offset + slideWidth + settings.slidesGap;
      }

      return offset + slideWidth;
    });
    setActiveSlideIndex((index) => index - 1);
  };

  const setActiveSlide = (index) => {
    if (activeSlideIndex === index) return;

    if (activeSlideIndex < index) {
      const diff = index - activeSlideIndex;
      setTrackOffset((offset) => {
        if (settings.slidesGap) {
          return offset - slideWidth * diff - settings.slidesGap * diff;
        }

        return offset - slideWidth * diff;
      });
    } else {
      const diff = activeSlideIndex - index;
      setTrackOffset((offset) => {
        if (settings.slidesGap) {
          return offset + slideWidth * diff + settings.slidesGap * diff;
        }

        return offset + slideWidth * diff;
      });
    }

    setActiveSlideIndex(index);
  };

  return {
    viewportRef,
    slideWidth,
    trackOffset,
    setActiveSlide,
    showPrevSlide,
    showNextSlide,
    activeSlideIndex,
    isLastSlide,
    isFirstSlide,
  };
};

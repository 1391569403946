import { createStoreEnvironment } from 'libjs-pdcore/store';
import { createSelectorHook, createDispatchHook } from 'react-redux';
import { DAEMON } from 'libjs-pdcore/store/constants';
import { fromJS } from 'immutable';

const SAGA_MODE = {
  DAEMON,
};

const {
  createStore,
  injectReducer,
  injectSaga,
  injectMultipleReducers,
  StoreProvider,
  connectToStore,
  injectMultipleSagas,
  StoreContext,
} = createStoreEnvironment();

const useSelector = createSelectorHook(StoreContext);
const useDispatch = createDispatchHook(StoreContext);

const store = createStore({}, fromJS({}));

export {
  createStore,
  injectReducer,
  injectSaga,
  injectMultipleReducers,
  StoreProvider,
  connectToStore,
  SAGA_MODE,
  injectMultipleSagas,
  useSelector,
  useDispatch,
  store,
};

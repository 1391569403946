import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

export const Layout = ({ children, background }) => {
  return (
    <Styled.Wrapper background={background}>
      <Styled.Layout>{children}</Styled.Layout>
    </Styled.Wrapper>
  );
};

Layout.propTypes = {
  background: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

import styled from 'styled-components';

export const Footer = styled.div`
  padding-top: 40px;
`;

export const Socials = styled.div`
  display: flex;
  margin-bottom: 30px;
  width: fit-content;

  @media (max-width: 960px) {
    margin: 0px auto 30px;
  }
`;

export const SocialLink = styled.a`
  display: block;
  width: 24px;
  height: 24px;
  background: url(${({ icon }) => icon});
  margin-right: 10px;
`;

export const Link = styled.a`
  color: #666;
  font-size: 12px;
  text-decoration: none;
  line-height: 1.2;
  margin-right: 10px;

  &:hover {
    color: #242424;
  }
`;

export const Rights = styled.div`
  font-weight: 400;
  line-height: 1.5;
  color: #242424;
  font-size: 12px;

  @media (max-width: 960px) {
    margin-bottom: 10px;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 48px;
  flex-direction: column;
  margin: 120px auto;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    gap: 36px;
    margin: 60px auto;
  }
`;

export const Title = styled.div`
  color: #242424;
  font-weight: 700;
  font-size: 24px;
  text-align: center;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    font-size: 18px;
    margin-top: 40px;
  }
`;

export const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px;

  ${({ theme }) => theme.media.down(theme.breakpoints.small, { strict: false })} {
    max-width: 840px;
  }

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    margin-top: 36px;
    gap: 25px 20px;
  }
`;

export const Logo = styled.img`
  display: block;
`;

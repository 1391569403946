import React, { Children } from 'react';
import { Track, Viewport, Slide, Dots, Dot, Slides } from './styled';
import { PrevArrow } from './PrevArrow';
import { NextArrow } from './NextArrow';
import { SwipeObserver } from '../../containers/SwipeObserver';
import { useSlider } from './hooks/useSlider';

const defaultSettings = {
  speed: 400,
  showDots: true,
  showArrows: true,
  padding: 20,
  slidesGap: 10,
  draggable: true,
};
export const Slider = ({ children, settings = defaultSettings } = {}) => {
  const numberOfSlides = children.length;
  const {
    viewportRef,
    slideWidth,
    trackOffset,
    setActiveSlide,
    showPrevSlide,
    showNextSlide,
    activeSlideIndex,
    isLastSlide,
    isFirstSlide,
  } = useSlider({ settings, numberOfSlides });

  return (
    <div>
      <Slides>
        {settings.showArrows && (
          <PrevArrow onClick={showPrevSlide} disabled={isFirstSlide} />
        )}
        <Viewport ref={viewportRef} settings={settings}>
          <Track offset={trackOffset} speed={settings.speed} settings={settings}>
            {Children.map(children, (child, index) =>
              settings.draggable ? (
                <SwipeObserver
                  key={`slide-${index}`}
                  onSwipeLeft={showNextSlide}
                  onSwipeRight={showPrevSlide}
                >
                  {(observableProps) => (
                    <Slide
                      width={`${slideWidth}px`}
                      className="slide"
                      {...observableProps}
                    >
                      {child}
                    </Slide>
                  )}
                </SwipeObserver>
              ) : (
                <Slide key={`slide-${index}`} width={`${slideWidth}px`} className="slide">
                  {child}
                </Slide>
              ),
            )}
          </Track>
        </Viewport>
        {settings.showArrows && (
          <NextArrow onClick={showNextSlide} disabled={isLastSlide} />
        )}
      </Slides>
      <Dots>
        {Children.map(children, (child, index) => (
          <Dot
            key={`slide-${index}-dot`}
            active={index === activeSlideIndex}
            onClick={() => {
              setActiveSlide(index);
            }}
          />
        ))}
      </Dots>
    </div>
  );
};

import React from 'react';
import { isExperimentVersion } from 'utils/isExperimentVersion';
import { Layout } from 'components/Layout';
import { Header } from 'components/Header';
import { Intro } from './components/Intro';
import { Form } from './components/Form';
import { Clients } from './components/Clients';
import { Footer } from './components/Footer';
import * as Styled from './styled';
import { RequestProductDemoExperiment } from '../RequestProductDemoExperiment';

export const RequestProductDemoRaffle = () => {
  if (isExperimentVersion) {
    return <RequestProductDemoExperiment />;
  }

  return (
    <Layout background="rgb(243, 245, 246)">
      <Header />
      <Styled.FormWrapper>
        <Form />
        <Intro />
      </Styled.FormWrapper>
      <Clients />
      <Footer />
    </Layout>
  );
};

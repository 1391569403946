import { useCallback } from 'react';
import { useSelector, useDispatch } from 'store';
import { ChiliPiperService } from 'services/ChiliPiperService';
import {
  LOCALIZATION_PRODUCT_DEMO_HUBSPOT_FORMS,
  PRODUCT_DEMO_CHILIPIPER_HUBSPOT_FORM,
  PRODUCT_DEMO_REGULAR_HUBSPOT_FORM,
} from 'services/HubSpotService/constants';
import { FIELDS } from '../../Application/constants';
import {
  isEnglishActiveLanguageSelector,
  activeLanguageSelector,
  companySizeFieldSelector,
  requestFormModelSelector,
} from 'containers/Application/selectors';
import { submitDemo as submitDemoAction } from 'containers/Application/actions';
import { Form } from '../components/Form/styled';

const defaultFields = [
  FIELDS.FIRST_NAME,
  FIELDS.LAST_NAME,
  FIELDS.EMAIL,
  FIELDS.PHONE,
  FIELDS.COMPANY_NAME,
  FIELDS.COMPANY_SIZE,
  FIELDS.PREFERRED_LANGUAGE,
];

export const useSubmitForm = () => {
  const isEnglishActiveLanguage = useSelector(isEnglishActiveLanguageSelector);
  const activeLanguage = useSelector(activeLanguageSelector);
  const companySizeField = useSelector(companySizeFieldSelector);
  const requestFormModel = useSelector(requestFormModelSelector);
  const { key: activeLanguageKey } = activeLanguage;

  const dispatch = useDispatch();

  const submitForm = useCallback(
    (e) => {
      e.preventDefault();

      const fieldIds = [...defaultFields];
      let hubSpotFrom = null;

      const isShowChiliPiperCalendar = ChiliPiperService.isShowChiliPiperCalendar({
        companySizeRange: companySizeField.value,
        isEnglishActiveLanguage,
      });

      if (!isEnglishActiveLanguage) {
        hubSpotFrom = LOCALIZATION_PRODUCT_DEMO_HUBSPOT_FORMS[activeLanguageKey];
      } else {
        hubSpotFrom = isShowChiliPiperCalendar
          ? PRODUCT_DEMO_CHILIPIPER_HUBSPOT_FORM
          : PRODUCT_DEMO_REGULAR_HUBSPOT_FORM;
      }

      dispatch(
        submitDemoAction({
          fieldIds,
          hubSpotFrom,
          isShowChiliPiperCalendar,
        }),
      );
    },
    [isEnglishActiveLanguage, dispatch, companySizeField, activeLanguageKey],
  );

  const submitFormIfValid = useCallback(
    (e) => {
      if (requestFormModel.isFormValid({ fieldIds: defaultFields })) {
        submitForm(e);
      } else {
        const form = document.body.querySelector(Form);

        if (form) {
          form.scrollIntoView({ block: 'center' });
          const firstNameInput = form.elements[FIELDS.FIRST_NAME];
          firstNameInput?.focus();
        }
      }
    },
    [submitForm, requestFormModel],
  );

  return {
    submitForm,
    submitFormIfValid,
  };
};

import styled from 'styled-components';
import locationIcon from './images/location.svg';
import arrowIcon from './images/arrow.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.6;

  &:hover {
    opacity: 0.7;
  }

  &:before {
    content: '';
    display: block;
    background: url(${locationIcon});
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  &:after {
    content: '';
    display: block;
    background: url(${arrowIcon});
    width: 14px;
    height: 14px;
    margin-left: 2px;
  }
`;

export const LanguageDropdown = styled.select`
  cursor: pointer;
  background: none;
  border: none;
  color: #242424;
  font-size: 12px;
  line-height: 14px;
  -webkit-appearance: none;
  outline: none;
`;

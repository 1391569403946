import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';

export const HtmlHead = ({ locale, page }) => {
  const { t } = useTranslation();
  const { pathname } = window.location;
  // eslint-disable-next-line i18next/no-literal-string
  const url = 'https://demo.pandadoc.com' + pathname;

  return (
    <Helmet>
      <link rel="canonical" href={url} />
      <meta property="og:locale" content={locale} />
      <meta property="og:url" content={url} />
      <title>
        {t('PandaDoc Demo Request Form - Request a Free Live Personalized Demo')}
      </title>
      <meta
        name="description"
        content={t(
          'See PandaDoc in action with a live personalized demo, no commitments required. Learn how to automate your work with proposals and contracts. Request a demo.',
        )}
      />
      <meta
        property="og:title"
        content={t('PandaDoc Demo Request Form - Request a Free Live Personalized Demo')}
      />
      <meta
        property="og:description"
        content={t(
          'See PandaDoc in action with a live personalized demo, no commitments required. Learn how to automate your work with proposals and contracts. Request a demo.',
        )}
      />
      {!page && <link rel="alternate" hrefLang="en" href="https://demo.pandadoc.com/" />}
      {!page && (
        <link rel="alternate" hrefLang="fr" href="https://demo.pandadoc.com/fr" />
      )}
      {!page && (
        <link rel="alternate" hrefLang="nl" href="https://demo.pandadoc.com/nl" />
      )}
      {!page && (
        <link rel="alternate" hrefLang="es" href="https://demo.pandadoc.com/es" />
      )}
      {!page && (
        <link rel="alternate" hrefLang="pl" href="https://demo.pandadoc.com/pl" />
      )}
      {!page && (
        <link rel="alternate" hrefLang="de" href="https://demo.pandadoc.com/de" />
      )}
      {!page && (
        <link rel="alternate" hrefLang="sv" href="https://demo.pandadoc.com/sv" />
      )}
    </Helmet>
  );
};

HtmlHead.propTypes = {
  locale: PropTypes.string.isRequired,
  page: PropTypes.string,
};

HtmlHead.defaultProps = {
  page: '',
};

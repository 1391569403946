import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'containers/Application/model/fieldPropTypes';
import { FIELDS, COMPANY_SIZES } from 'containers/Application/constants';
import { FieldWrapper, FieldLabel, FieldError } from '../common';
import * as Styled from './styled';

const chooseOfOptions = {
  [FIELDS.COMPANY_SIZE]: COMPANY_SIZES,
};

export const SelectField = ({
  fieldModel: { id, value, error },
  validateField,
  setFieldValue,
  label,
}) => {
  const onChange = (event) => {
    setFieldValue({
      id,
      value: event.target.value,
    });
  };

  const onBlur = () => {
    validateField({ id });
  };

  const renderOptions = () =>
    chooseOfOptions[id].map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));

  return (
    <FieldWrapper>
      <FieldLabel>{label}</FieldLabel>
      <Styled.Svg xmlns="http://www.w3.org/2000/svg" width="9" height="6">
        <path d="M7.73 0l1 1-4.37 4.36L0 1l1-1 3.36 3.36z" fill="#888" />
      </Styled.Svg>
      <Styled.Select
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        id={id}
        error={error}
      >
        <option value="" hidden></option>
        {renderOptions()}
      </Styled.Select>
      {error && <FieldError>{error}</FieldError>}
    </FieldWrapper>
  );
};

SelectField.propTypes = {
  fieldModel: PropTypes.shape(fieldPropTypes).isRequired,
  validateField: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

import { STEPS } from './constants';
import * as Styled from './styled';
import { Step } from './Step';

export const Steps = () => (
  <Styled.Steps>
    {STEPS.map((label, index) => (
      <Step key={label} label={label} order={index + 1} />
    ))}
  </Styled.Steps>
);

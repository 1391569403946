import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ background }) => background};
  width: 100%;
`;

export const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  padding: 0;

  ${({ theme }) => theme.media.down(theme.breakpoints.large)} {
    padding: 0 50px;
  }

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    padding: 0 20px;
  }
`;

import { Record, Map } from 'immutable';

const DEFAULT_FIELDS_LIST_PROPS = {
  fields: new Map(),
  isLoading: false,
};

export class RequestForm extends Record(DEFAULT_FIELDS_LIST_PROPS) {
  constructor(fields = {}) {
    super({
      fields: new Map(fields),
    });
  }

  setLaoding(isLoading) {
    return this.set('isLoading', isLoading);
  }

  setFieldValue({ id, value }) {
    const fields = this.get('fields');
    const field = fields.get(id);

    return this.set('fields', fields.set(id, field.setValue(value)));
  }

  validateField({ id }) {
    const fields = this.get('fields');
    const field = fields.get(id);

    return this.set('fields', fields.set(id, field.validate()));
  }

  validateForm({ fieldIds }) {
    const fields = this.get('fields');

    return this.set(
      'fields',
      fields.map((field) => {
        if (fieldIds.includes(field.get('id'))) {
          return field.validate();
        }
        return field;
      }),
    );
  }
  isFormValid({ fieldIds }) {
    const fields = this.get('fields');

    return fields.every((field) => {
      if (fieldIds.includes(field.get('id'))) {
        return field.isValid();
      }
      return true;
    });
  }

  setError({ id, error }) {
    const fields = this.get('fields');
    const field = fields.get(id);

    return this.set('fields', fields.set(id, field.setError(error)));
  }
}

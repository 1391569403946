import { useState } from 'react';

const THRESHOLD = 50;
export const SwipeObserver = ({ children, onSwipeLeft, onSwipeRight }) => {
  const [isTouched, setIsTouched] = useState(false);
  const [startPosition, setStartPosition] = useState({
    x: 0,
    y: 0,
  });
  const onMouseLeave = () => {
    setIsTouched(false);
  };
  const onMouseUp = () => {
    setIsTouched(false);
  };

  const onMouseDown = (e) => {
    setIsTouched(true);
    setStartPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const onMouseMove = (e) => {
    if (!isTouched) return;

    const { clientX } = e;
    const isSwipeLeft =
      clientX < startPosition.x && Math.abs(clientX - startPosition.x) >= THRESHOLD;
    const isSwipeRight =
      clientX > startPosition.x && Math.abs(clientX - startPosition.x) >= THRESHOLD;

    if (isSwipeRight) {
      setIsTouched(false);
      onSwipeRight();
    }

    if (isSwipeLeft) {
      setIsTouched(false);
      onSwipeLeft();
    }
  };

  const onTouchMove = (e) => {
    if (!isTouched) return;
    const touch = e.touches[0];
    const { clientX } = touch;

    const isSwipeLeft =
      clientX < startPosition.x && Math.abs(clientX - startPosition.x) >= THRESHOLD;
    const isSwipeRight =
      clientX > startPosition.x && Math.abs(clientX - startPosition.x) >= THRESHOLD;

    if (isSwipeRight) {
      setIsTouched(false);
      onSwipeRight();
    }

    if (isSwipeLeft) {
      setIsTouched(false);
      onSwipeLeft();
    }
  };

  const onTouchStart = (e) => {
    const touch = e.touches[0];
    const { clientX, clientY } = touch;
    setIsTouched(true);
    setStartPosition({
      x: clientX,
      y: clientY,
    });
  };

  const onTouchEnd = () => {
    setIsTouched(false);
  };

  return children({
    onMouseLeave,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onTouchStart,
    onTouchEnd,
    onTouchMove,
  });
};

import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;

  ${({ theme }) => theme.media.down(theme.breakpoints.small)} {
    align-items: center;
    flex-direction: column;
  }

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    padding-bottom: 0;
  }
`;
export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
  max-width: 660px;
  flex: 1 1 auto;
  height: fit-content;
  background: #ffffff;
  padding: 40px;

  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  ${({ theme }) => theme.media.down(theme.breakpoints.small)} {
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
  }

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    padding: 20px;
  }
`;

export const Disclaimer = styled.div`
  margin-top: 15px;
  color: #9d9d9d;
  font-size: 12px;
`;

export const PrivacyNotice = styled.div`
  font-size: 12px;
  color: #242424;
  line-height: 16px;
  text-align: left;
`;

export const Link = styled.a`
  color: #242424;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid #242424;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
`;
export const Button = styled.button`
  color: #fff;
  background-color: #47b972;
  padding: 12px 20px;
  margin: 12px auto auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.25s;
  height: 46px;

  &:hover {
    background-color: #43af6c;
  }

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    width: 100%;
  }
`;

export const Title = styled.div`
  color: #242424;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  width: 100%;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    font-size: 18px;
  }
`;

import React from 'react';

export const NextSlideIcon = ({ fill } = { fill: '#242424' }) => (
  <svg
    width="23"
    height="42"
    viewBox="0 0 23 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.42 0.5L22.12 21.21L1.42 41.92L0 40.5L19.3 21.21L0 1.92L1.42 0.5Z"
      fill={fill}
    />
  </svg>
);

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StoreProvider, store } from 'store';
import { BreakpointProvider } from '@ui-kit.next/utils/withBreakpoint';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import defaultTheme from '@ui-kit.next/themes/Default';
import { LANGUAGE_KEYS, LANGUAGES, BASE_URL, DEMO_URL } from 'appConstants';
import { Application } from './containers/Application';
import { GlobalStyle } from './global-styles';
import { isExperimentVersion } from 'utils/isExperimentVersion';

import englishTranslation from 'locales/en-US/request-demo.json';
import spanishTranslation from 'locales/es-ES/request-demo.json';
import frenchTranslation from 'locales/fr-FR/request-demo.json';
import nederlandTranslation from 'locales/nl-NL/request-demo.json';
import polandTranslation from 'locales/pl-PL/request-demo.json';
import portugueseTranslation from 'locales/pt-PT/request-demo.json';
import germanyTranslation from 'locales/de-DE/request-demo.json';
import swedenTranslation from 'locales/sv-SE/request-demo.json';

const Demo = () => {
  const [, pathname] = window.location.pathname.split('/');
  const activeLanguage =
    LANGUAGES.find(({ path }) => path === `/${pathname}`) || LANGUAGES[0]; //English is default;

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        [LANGUAGE_KEYS.ENGLISH]: {
          translation: englishTranslation['request-demo'],
        },
        [LANGUAGE_KEYS.FRENCH]: {
          translation: frenchTranslation['request-demo'],
        },
        [LANGUAGE_KEYS.SPANISH]: {
          translation: spanishTranslation['request-demo'],
        },
        [LANGUAGE_KEYS.NEDERLAND]: {
          translation: nederlandTranslation['request-demo'],
        },
        [LANGUAGE_KEYS.POLAND]: {
          translation: polandTranslation['request-demo'],
        },
        [LANGUAGE_KEYS.PORTUGUESE]: {
          translation: portugueseTranslation['request-demo'],
        },
        [LANGUAGE_KEYS.GERMANY]: {
          translation: germanyTranslation['request-demo'],
        },
        [LANGUAGE_KEYS.SWEDEN]: {
          translation: swedenTranslation['request-demo'],
        },
      },
      lng: activeLanguage.key, // if you're using a language detector, do not define the lng option
      fallbackLng: LANGUAGE_KEYS.ENGLISH,
      debug: false,
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      nsSeparator: false,
      keySeparator: false,
      react: {
        // eslint-disable-next-line i18next/no-literal-string
        transKeepBasicHtmlNodesFor: ['br'],
      },
    });

  return (
    <>
      <GlobalStyle />
      <StoreProvider store={store}>
        <ThemeProvider theme={defaultTheme}>
          <BreakpointProvider>
            <Router>
              <Switch>
                <Route path={`${BASE_URL}${DEMO_URL}`} component={Application} />
              </Switch>
            </Router>
          </BreakpointProvider>
        </ThemeProvider>
      </StoreProvider>
    </>
  );
};

const rootElement = document.getElementById('root');

// Current experiment version includes slider component which is not working with hydration
if (rootElement.hasChildNodes() && !isExperimentVersion) {
  ReactDOM.hydrate(
    <React.StrictMode>
      <Demo />
    </React.StrictMode>,
    rootElement,
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Demo />
    </React.StrictMode>,
    rootElement,
  );
}

import styled from 'styled-components';

export const Slides = styled.div.attrs({
  className: 'slider-list',
})`
  --arrow-wrapper-width: 50px;
  --mobile-slides-gap: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const Viewport = styled.div.attrs({
  className: 'viewport',
})`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  overflow-x: hidden;
  width: ${({ settings }) =>
    settings.showArrows ? 'calc(100% - var(--arrow-wrapper-width) * 2)' : '100%'};
  padding: ${({ settings }) => (settings.padding ? `0 ${settings.padding}px` : '0')};

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    width: 100%;
  }
`;

export const Track = styled.div.attrs({
  className: 'slider-track',
})`
  width: fit-content;
  height: max-content;
  display: flex;
  transform: translateX(${({ offset }) => offset}px);
  transition: transform ${({ speed }) => speed}ms ease-in-out;
  gap: ${({ settings }) => (settings?.slidesGap ? `${settings.slidesGap}px` : 0)};
`;

export const Slide = styled.div.attrs({
  className: 'slide',
})`
  width: ${({ width }) => width};
`;

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--arrow-wrapper-width, 50px);
  background: #fff;
  cursor: pointer;
`;

export const PrevArrowWrapper = styled(ArrowWrapper).attrs({
  className: 'prev-slide',
})`
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`;

export const NextArrowWrapper = styled(ArrowWrapper).attrs({
  className: 'next-slide',
})`
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`;

export const Dots = styled.div.attrs({
  className: 'slider-dots',
})`
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
  width: fit-content;
  gap: 10px;
`;

export const Dot = styled.div.attrs({
  className: 'slider-dot',
})`
  width: 10px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background: #242424;
  opacity: ${({ active }) => (active ? 1 : 0.2)};
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

import React from 'react';

export const PrevSlideIcon = ({ fill } = { fill: '#242424' }) => (
  <svg
    width="23"
    height="42"
    viewBox="0 0 23 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7 41.92L0 21.21L20.7 0.5L22.12 1.92L2.82 21.21L22.12 40.5L20.7 41.92Z"
      fill={fill}
    />
  </svg>
);

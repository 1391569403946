export const getLocationQuery = () => {
  const map = {};

  const search = window.location.search;

  if (search) {
    const params = search.substr(1).split('&');

    params.forEach((uri) => {
      const keyVal = uri.split('=');
      map[decodeURIComponent(keyVal[0])] = decodeURIComponent(keyVal[1]);
    });
  }

  return map;
};

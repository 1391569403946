import styled from 'styled-components';

export const FormsIntro = styled.div`
  color: #242424;
  font-size: 18px;
  padding-left: 20px;
  letter-spacing: 0;
  line-height: 27px;
  max-width: 480px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 960px) {
    padding-left: 0px;
    margin: 40px auto 0px;
    max-width: 650px;
  }

  @media (max-width: 768px) {
    max-width: 526px;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin: 0px;
  color: rgb(36, 36, 36);

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 32px;
  }

  @media (max-width: 1024px) {
    br {
      display: none;
    }
  }
`;

export const Description = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
  color: #242424;
`;

export const TermsNotice = styled.p`
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 21px;
  font-size: 12px;
  color: #242424;
  opacity: 70%;
`;

export const P = styled.p`
  margin-bottom: 10px;
`;

export const Link = styled.a`
  color: #242424;
`;

export const List = styled.ul`
  margin: 0px;
  padding-left: 26px;
`;

/* eslint-disable i18next/no-literal-string */
import Cookies from 'js-cookie';
import { getTypeOfDevice } from 'libjs-pdbusiness/modules/UserForm/utils/getTypeOfDevice';
import { getExperimentInfo } from 'libjs-pdbusiness/modules/UserForm/utils/getExperimentInfo';
import { getGoogleAnalyticClientId } from 'libjs-pdbusiness/modules/UserForm/utils/getGoogleAnalyticClientId';
import { getLocationQuery } from '../../utils/getLocationQuery';
import { HUBSPOT_FIELDS, HUBSPOT_UTM } from './constants';
import { APP_CATEGORY } from 'appConstants';

class HubSpot {
  constructor() {
    this.init();
  }

  init = () => {
    const locationQuery = getLocationQuery();

    for (const key in locationQuery) {
      if (key.startsWith('utm')) {
        const trimmedValue = locationQuery[key].substring(0, 64);
        Cookies.set(key, trimmedValue);
      }
    }
  };

  getDataToSend = ({ isRaffle = false }) => {
    const fields = [];

    const cookies = Cookies.get();

    for (const key in cookies) {
      if (key.startsWith('utm') && cookies[key] && HUBSPOT_UTM.indexOf(key) !== -1) {
        fields.push({ name: key, value: cookies[key] });
      }
    }

    const hubspotutk = Cookies.get('hubspotutk');

    if (hubspotutk) {
      fields.push({ name: 'hutk', value: hubspotutk });
    }

    const { gclid: googleClickId, ss: signupSource, cid: clickID } = getLocationQuery();

    if (signupSource) {
      fields.push({ name: HUBSPOT_FIELDS.SIGNUP_SOURCE, value: signupSource });
    }

    if (googleClickId) {
      fields.push({ name: HUBSPOT_FIELDS.GCLID, value: googleClickId });
    }

    if (clickID) {
      fields.push({ name: HUBSPOT_FIELDS.CLICK_ID, value: clickID });
    }

    const experimentInfo = getExperimentInfo(APP_CATEGORY);

    if (experimentInfo) {
      fields.push({ name: HUBSPOT_FIELDS.A_B_TEST_INFO, value: experimentInfo });
    }

    const typeOfDevice = getTypeOfDevice();

    fields.push({ name: HUBSPOT_FIELDS.DEVICE, value: typeOfDevice });

    try {
      const clientId = getGoogleAnalyticClientId();
      fields.push({ name: HUBSPOT_FIELDS.GA_CLIENT_ID, value: clientId });
    } catch (e) {
      fields.push({ name: HUBSPOT_FIELDS.GA_CLIENT_ID, value: 'no_ga_tracker_created' });
    }

    if (isRaffle) {
      fields.push({ name: HUBSPOT_FIELDS.RSCODE, value: 'raffle' });
    }

    return fields;
  };
}

const HubSpotService = new HubSpot();

export { HubSpotService };

import React from 'react';
import { useBreakpoint } from '@ui-kit.next/utils/withBreakpoint';
import { Slide } from './components/Slide';
import { Slider } from './components/Slider';
import { testimonials } from './components/Slide/constants';
import { SliderContainer, Background } from './styled';

export const Testimonials = () => {
  const { downXSmall } = useBreakpoint();

  const settings = {
    speed: 400,
    showDots: true,
    showArrows: !downXSmall,
    padding: downXSmall ? 20 : 0,
    slidesGap: downXSmall ? 10 : 0,
    draggable: downXSmall,
  };

  return (
    <Background>
      <SliderContainer>
        <Slider settings={settings}>
          {testimonials.map((slide, index) => (
            <Slide key={`${slide.name}-${index}`} {...slide} />
          ))}
        </Slider>
      </SliderContainer>
    </Background>
  );
};

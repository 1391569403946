import { CountryCode } from 'libjs-pdbusiness/constants';

export const CONTAINER_CLASS = 'phone-field-container';

export const PREFERRED_COUNTRIES = {
  [CountryCode.UNITED_STATES]: [
    CountryCode.UNITED_STATES,
    CountryCode.CANADA,
    CountryCode.AUSTRALIA,
  ],
  [CountryCode.NETHERLANDS]: [CountryCode.NETHERLANDS, CountryCode.BELGIUM],
  [CountryCode.FRANCE]: [
    CountryCode.FRANCE,
    CountryCode.CANADA,
    CountryCode.BELGIUM,
    CountryCode.SWITZERLAND,
  ],
  [CountryCode.GERMANY]: [
    CountryCode.GERMANY,
    CountryCode.SWITZERLAND,
    CountryCode.AUSTRIA,
  ],
  [CountryCode.POLAND]: [
    CountryCode.POLAND,
    CountryCode.UNITED_KINGDOM,
    CountryCode.UNITED_STATES,
  ],
  [CountryCode.PORTUGAL]: [
    CountryCode.PORTUGAL,
    CountryCode.UNITED_STATES,
    CountryCode.BRAZIL,
    CountryCode.MOZAMBIQUE,
  ],
  [CountryCode.SPAIN]: [CountryCode.SPAIN, CountryCode.COLOMBIA, CountryCode.MEXICO],
  [CountryCode.SWEDEN]: [CountryCode.SWEDEN, CountryCode.FINLAND],
};

import { getExperimentInfo } from 'libjs-pdbusiness/modules/UserForm/utils/getExperimentInfo';
import { eventN } from '@jitsu/eventnative';
import { APP_CATEGORY } from 'appConstants';

class Analytics {
  constructor() {
    this.isEnabled = false;
    // eslint-disable-next-line i18next/no-literal-string
    this.category = APP_CATEGORY;
    this.segmentOptions = {
      integrations: {
        Pendo: false,
        Hotjar: false,
        Appcues: false,
        // Intercom: false,
        FullStory: false,
        // Amplitude: false,
      },
    };
  }

  getSegmentScript = (jitsuConfig) => {
    /* eslint-disable */
    !(function () {
      const analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked)
          window.console &&
            console.error &&
            console.error('Segment snippet included twice.');
        else {
          analytics.invoked = !0;
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
            'addSourceMiddleware',
          ];
          analytics.factory = function (t) {
            return function () {
              const e = Array.prototype.slice.call(arguments);
              e.unshift(t);
              analytics.push(e);
              return analytics;
            };
          };
          for (let e of analytics.methods) {
            analytics[e] = analytics.factory(e);
          }
          analytics.load = function (t, e) {
            const n = document.createElement('script');
            n.type = 'text/javascript';
            n.async = !0;
            n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';

            n.onload = () => {
              if (!jitsuConfig.isEnabled) {
                return;
              }

              eventN.init({
                key: jitsuConfig.key,
                tracking_host: jitsuConfig.host,
                cookie_domain: jitsuConfig.cookieDomain,
              });

              eventN.interceptAnalytics(window.analytics);
              window.analytics.page();
            };

            const a = document.getElementsByTagName('script')[0];
            a.parentNode.insertBefore(n, a);
            analytics._loadOptions = e;
          };

          analytics.SNIPPET_VERSION = '4.1.0';
        }
    })();
    /* eslint-enable */
  };

  init = ({ segmentKey, isSegmentEnabled, jitsuConfig }) => {
    this.isEnabled = isSegmentEnabled && isSegmentEnabled !== 'false';

    if (this.isEnabled) {
      this.getSegmentScript(jitsuConfig);
    }
    if (!window.analytics || !window.analytics.load) return;

    window.analytics.load(segmentKey, this.segmentOptions);

    if (!jitsuConfig.isEnabled) {
      window.analytics.page();
    }

    this.category = getExperimentInfo(APP_CATEGORY);
  };

  track = (action, options = {}) => {
    if (!this.isEnabled || !window.analytics || !window.analytics.track) {
      // eslint-disable-next-line i18next/no-literal-string
      console.info('Analytics.track', {
        action,
        options: {
          ...options,
          category: this.category,
          url: window.location.href,
          path: window.location.pathname,
        },
      });
      return;
    }

    window.analytics.track(
      action,
      {
        ...options,
        category: this.category,
        url: window.location.href,
        path: window.location.pathname,
        title: action,
      },
      this.segmentOptions,
    );
  };

  page = (name, options = {}) => {
    if (!this.isEnabled || !window.analytics || !window.analytics.page) {
      // eslint-disable-next-line i18next/no-literal-string
      console.info('Analytics.page', {
        name,
        options,
      });
      return;
    }

    window.analytics.page(
      name,
      {
        ...options,
        url: window.location.href,
        path: window.location.pathname,
        title: name,
        category: this.category,
      },
      this.segmentOptions,
      this.segmentOptions,
    );
  };
}

const AnalyticsService = new Analytics();

export { AnalyticsService };

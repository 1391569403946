import { defineMessage } from '@libjs-pdlocalization/components/utils';
import { RequestForm } from './RequestForm';
import { Field } from './Field';
import { FIELDS, NOT_VALID_EMAIL_ERROR } from '../constants';

const EmailValidator = {
  regex: {
    IsEmpty: /.+/,
    isOutOfBorder: /^.{1,64}$/,
    isMatchThePattern: /.+\@.+\..+/,
  },
  errors: {
    IsEmpty: NOT_VALID_EMAIL_ERROR,
    isOutOfBorder: defineMessage('Maximum length is 255 characters'),
    isMatchThePattern: NOT_VALID_EMAIL_ERROR,
  },
};

export const createRequestFormModel = () =>
  new RequestForm({
    [FIELDS.FIRST_NAME]: new Field({
      id: FIELDS.FIRST_NAME,
      validator: {
        regex: {
          IsEmpty: /.+/,
          isOutOfBorder: /^.{1,64}$/,
        },
        errors: {
          IsEmpty: defineMessage('Please enter your first name'),
          isOutOfBorder: defineMessage('Maximum length is 64 characters'),
        },
      },
    }),

    [FIELDS.LAST_NAME]: new Field({
      id: FIELDS.LAST_NAME,
      validator: {
        regex: {
          IsEmpty: /.+/,
          isOutOfBorder: /^.{1,64}$/,
        },
        errors: {
          IsEmpty: defineMessage('Please enter your last name'),
          isOutOfBorder: defineMessage('Maximum length is 64 characters'),
        },
      },
    }),

    [FIELDS.EMAIL]: new Field({
      id: FIELDS.EMAIL,
      validator: EmailValidator,
    }),

    [FIELDS.EMAIL_FOR_SSD]: new Field({
      id: FIELDS.EMAIL_FOR_SSD,
      validator: EmailValidator,
    }),

    [FIELDS.PHONE]: new Field({
      id: FIELDS.PHONE,
      validator: {
        regex: {
          IsEmpty: /.+/,
          isOutOfBorder: /^.{1,64}$/,
          isMatchThePattern: /^(?=.*[0-9])[- +()0-9.]+$/,
          /* Our phone field format its value so sometimes empty phone numbers pass previous validator because of
          formatting applied. It's not ideal but at least would check that phone number is not too short */
          isNotOnlyCountryCode: /^.{8,}$/,
        },
        errors: {
          IsEmpty: defineMessage('Please enter your phone number'),
          isOutOfBorder: defineMessage('Maximum length is 64 characters'),
          isMatchThePattern: defineMessage('Please enter a valid phone number'),
          isNotOnlyCountryCode: defineMessage('Phone number seems to be too short'),
        },
      },
    }),

    [FIELDS.COMPANY_NAME]: new Field({
      id: FIELDS.COMPANY_NAME,
      validator: {
        regex: {
          IsEmpty: /.+/,
          isOutOfBorder: /^.{1,255}$/,
        },
        errors: {
          IsEmpty: defineMessage('Please enter your company name'),
          isOutOfBorder: defineMessage('Maximum length is 255 characters'),
        },
      },
    }),

    [FIELDS.COMPANY_SIZE]: new Field({
      id: FIELDS.COMPANY_SIZE,
      validator: {
        regex: {
          IsEmpty: /.+/,
        },
        errors: {
          IsEmpty: defineMessage('Please enter your company size'),
        },
      },
    }),

    [FIELDS.PREFERRED_LANGUAGE]: new Field({
      id: FIELDS.PREFERRED_LANGUAGE,
      validator: {
        regex: {
          IsEmpty: /.+/,
        },
        errors: {
          IsEmpty: defineMessage('Please enter your preferred language'),
        },
      },
    }),
  });

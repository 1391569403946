import styled from 'styled-components';
import { CommonInputStyles } from '../common';

export const Option = styled.option`
  color: rgb(0, 0, 0);
`;

export const Select = styled.select`
  ${CommonInputStyles}
  appearance: none;
`;

export const Svg = styled.svg`
  position: absolute;
  right: 12px;
  top: 22px;
  pointer-events: none;
`;

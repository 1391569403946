import styled from 'styled-components';
import Icon from '@ui-kit.next/components/Icon';
import CheckmarkGlyph from '@ui-kit.next/icons/Checkmark.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  box-sizing: border-box;
  background: #248567;
  width: 460px;
  min-width: 348px;
  flex: 0 1 auto;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #fff;
  padding: 40px 0;
  overflow-x: hidden;

  ${({ theme }) => theme.media.down(theme.breakpoints.small)} {
    min-width: auto;
    width: 100%;
    max-width: 660px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0;
  }

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    padding: 20px 0;
  }
`;

export const StepsTrack = styled.div`
  display: flex;
  transform: ${({ offset }) => `translateX(${-offset}px)`};

  & > * {
    box-sizing: border-box;
    min-width: ${({ width }) => width}px;
    padding: 0 40px;

    ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
      padding: 0 20px;
    }
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  width: 100%;

  ${Wrapper} & {
    margin-bottom: 28px;
  }

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    font-size: 18px;

    ${Wrapper} & {
      margin-bottom: 18px;
    }
  }
`;

export const Paragraph = styled.p`
  font-size: 18px;
  font-weight: 400;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    font-size: 16px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  gap: 14px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    font-size: 16px;
  }
`;

export const Bold = styled.b``;

export const Checkmark = styled(Icon).attrs({
  glyph: CheckmarkGlyph,
  size: Icon.SIZE.SMALL,
})``;

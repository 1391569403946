import React from 'react';
import { isPrerendering } from 'utils/isPrerendering';
import { Logo } from 'components/Logo';
import { SmallLogo } from 'components/SmallLogo';
import * as Styled from './styled';
import { useTheme } from 'styled-components';
import { useSubmitForm } from '../../hooks/useSubmitForm';
import { useMediaQuery } from '@ui-kit.next/core/hooks/useMediaQuery';

/* eslint-disable i18next/no-literal-string */
export const Header = () => {
  const theme = useTheme();
  const { matches } = useMediaQuery(theme.media.down(theme.breakpoints.xsmall));
  const { submitFormIfValid } = useSubmitForm();

  return (
    <Styled.Header>
      {!isPrerendering() && (matches ? <SmallLogo /> : <Logo />)}
      <Styled.Button onClick={submitFormIfValid}>Schedule a demo</Styled.Button>
    </Styled.Header>
  );
};

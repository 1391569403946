import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  gap: 16px;
  align-self: flex-end;
  margin-right: 40px;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    margin-right: 20px;
  }
`;

export const Button = styled.div.attrs(({ disabled, className }) => ({
  className: `${className} ${disabled ? 'disabled' : ''}`,
}))`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 1;

  &.disabled {
    cursor: initial;
    opacity: 0.6;
  }

  &.next:not(.disabled):hover {
    left: 3px;
  }

  &.prev:not(.disabled):hover {
    right: 3px;
  }
`;

import { createSelector } from 'reselect';
import { LANGUAGE_KEYS } from 'appConstants';
import { STORE_KEY, FIELDS } from './constants';

export const applicationModelSelector = (state) => state.get(STORE_KEY);

export const activeLanguageSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('activeLanguage').toJS(),
);

export const activeLanguageKeySelector = createSelector(
  activeLanguageSelector,
  (activeLanguage) => activeLanguage.key,
);

export const activeLanguageCountryCodeSelector = createSelector(
  activeLanguageSelector,
  (activeLanguage) => activeLanguage.locale?.split('_')?.[1],
);

export const isEnglishActiveLanguageSelector = createSelector(
  activeLanguageKeySelector,
  (activeLanguageKey) => activeLanguageKey === LANGUAGE_KEYS.ENGLISH,
);

export const isApplicationInitializedSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('isInitialized'),
);

export const applicationConfigSelector = createSelector(
  applicationModelSelector,
  (applicationModel) => applicationModel.get('appConfig'),
);

export const requestFormModelSelector = createSelector(
  applicationModelSelector,
  (requestFormModel) => requestFormModel.get('requestForm'),
);

export const countryCodeSelector = createSelector(
  applicationModelSelector,
  (requestFormModel) => requestFormModel.get('countryCode'),
);

export const fieldsModelSelector = createSelector(
  requestFormModelSelector,
  (requestFormModel) => requestFormModel.get('fields'),
);

export const fieldsSelector = createSelector(
  fieldsModelSelector,
  (models) => models.toJS() || {},
);

export const firstNameFieldSelector = createSelector(fieldsModelSelector, (models) =>
  models.get(FIELDS.FIRST_NAME, {}).toJS(),
);

export const lastNameFieldSelector = createSelector(fieldsModelSelector, (models) =>
  models.get(FIELDS.LAST_NAME, {}).toJS(),
);

export const workEmailFieldSelector = createSelector(fieldsModelSelector, (models) =>
  models.get(FIELDS.EMAIL, {}).toJS(),
);

export const emailForSSDFieldSelector = createSelector(fieldsModelSelector, (models) =>
  models.get(FIELDS.EMAIL_FOR_SSD, {}).toJS(),
);

export const phoneNumberFieldSelector = createSelector(fieldsModelSelector, (models) =>
  models.get(FIELDS.PHONE, {}).toJS(),
);

export const companyNameFieldSelector = createSelector(fieldsModelSelector, (models) =>
  models.get(FIELDS.COMPANY_NAME, {}).toJS(),
);

export const companySizeFieldSelector = createSelector(fieldsModelSelector, (models) =>
  models.get(FIELDS.COMPANY_SIZE, {}).toJS(),
);

export const preferredLanguageFieldSelector = createSelector(
  fieldsModelSelector,
  (models) => models.get(FIELDS.PREFERRED_LANGUAGE, {}).toJS(),
);

export const makeIsFormHasErrorsSelector = ({ fieldIds }) =>
  createSelector(
    fieldsModelSelector,
    (models) =>
      !models.some((model) => fieldIds.includes(model.get('id')) && model.get('error')),
  );

export const isLoadingSelector = createSelector(
  requestFormModelSelector,
  (requestFormModel) => requestFormModel.get('isLoading'),
);

import { Record, fromJS } from 'immutable';

const NULL_VALUE = null;

const DEFAULT_FIELD_PROPS = {
  id: '',
  value: NULL_VALUE,
  error: '',
  isPristine: true,
  validator: fromJS({
    regex: {},
    errors: {},
  }),
};

export class Field extends Record(DEFAULT_FIELD_PROPS) {
  constructor({ id, validator, value }) {
    super({
      id,
      value: value || '',
      validator: fromJS(validator),
    });
  }

  setValue(value) {
    return this.get('isPristine')
      ? this.set('value', value)
      : this.set('value', value).validate();
  }

  setError(error) {
    return this.set('error', error).set('isPristine', false);
  }

  validate() {
    const value = this.get('value').trim();
    const validator = this.get('validator').toJS();

    for (const key in validator.regex) {
      if (!validator.regex[key].test(value)) {
        return this.setError(validator.errors[key]);
      }
    }

    return this.setError('');
  }

  isValid() {
    const value = this.get('value').trim();
    const validator = this.get('validator').toJS();

    for (const key in validator.regex) {
      if (!validator.regex[key].test(value)) {
        return false;
      }
    }

    return true;
  }
}

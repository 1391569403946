import { LANGUAGE_KEYS } from 'appConstants';

export const LOCALIZATION_PRODUCT_DEMO_HUBSPOT_FORMS = {
  [LANGUAGE_KEYS.FRENCH]: {
    GUID: '7c8262ed-5bbc-40d6-8001-0fe0d0860041',
  },
  [LANGUAGE_KEYS.GERMANY]: {
    GUID: '45199fac-9740-406b-8fd1-79745a0e25d2',
  },
  [LANGUAGE_KEYS.NEDERLAND]: {
    GUID: '44a8a935-62e3-4a38-8040-dcfad25aa563',
  },
  [LANGUAGE_KEYS.POLAND]: {
    GUID: '7e92d09c-4740-4894-82a1-fcc924b5a35b',
  },
  [LANGUAGE_KEYS.SPANISH]: {
    GUID: '65c8f7f1-fe1d-4dbb-8731-24baf625232d',
  },
  [LANGUAGE_KEYS.SWEDEN]: {
    GUID: 'b8a2743c-5f13-4943-aeff-ad6489be031a',
  },
  [LANGUAGE_KEYS.PORTUGUESE]: {
    GUID: 'bb32facf-24a0-4024-8656-ee811435337a',
  },
};

export const PRODUCT_DEMO_REGULAR_HUBSPOT_FORM = {
  GUID: 'd64e2832-1e7e-44ac-a0ee-5cf3aa7dc7f1',
};

export const PRODUCT_DEMO_CHILIPIPER_HUBSPOT_FORM = {
  GUID: '0b0be3ff-a433-492b-9c63-18ff01287fe2',
};

export const API_DEMO_HUBSPOT_FORM = {
  GUID: '319fa767-7697-44c2-99ab-2d75147b0368',
};

export const HUBSPOT_FIELDS = {
  GA_CLIENT_ID: 'google_analytics_client_id',
  SIGNUP_SOURCE: 'signup_source',
  A_B_TEST_INFO: 'a_b_test_info',
  GCLID: 'gclid',
  CLICK_ID: 'cid',
  DEVICE: 'device',
  RSCODE: 'rscode',
};

export const HUBSPOT_UTM = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
];

import React from 'react';
import { connectToStore } from 'store';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { Trans, useTranslation } from '@libjs-pdlocalization/components/i18n';
import { FIELDS } from 'containers/Application/constants';
import {
  firstNameFieldSelector,
  lastNameFieldSelector,
  workEmailFieldSelector,
  phoneNumberFieldSelector,
  companyNameFieldSelector,
  companySizeFieldSelector,
  countryCodeSelector,
  isEnglishActiveLanguageSelector,
  activeLanguageSelector,
  activeLanguageCountryCodeSelector,
} from 'containers/Application/selectors';
import {
  setFieldValue as setFieldValueAction,
  validateField as validateFieldAction,
  submitDemo as submitDemoAction,
} from 'containers/Application/actions';
import { fieldPropTypes } from 'containers/Application/model/fieldPropTypes';
import {
  PRODUCT_DEMO_REGULAR_HUBSPOT_FORM,
  PRODUCT_DEMO_CHILIPIPER_HUBSPOT_FORM,
  LOCALIZATION_PRODUCT_DEMO_HUBSPOT_FORMS,
} from 'services/HubSpotService/constants';
import { ChiliPiperService } from 'services/ChiliPiperService';
import { TextField, PhoneField, SelectField } from 'components/Fields';
import * as Styled from './styled';

const defaultFields = [
  FIELDS.FIRST_NAME,
  FIELDS.LAST_NAME,
  FIELDS.EMAIL,
  FIELDS.PHONE,
  FIELDS.COMPANY_NAME,
  FIELDS.COMPANY_SIZE,
  FIELDS.PREFERRED_LANGUAGE,
];

const RequestProductDemoOnPopupContainer = ({
  firstNameField,
  lastNameField,
  workEmailField,
  phoneNumberField,
  companyNameField,
  companySizeField,
  setFieldValue,
  validateField,
  submitDemo,
  countryCode,
  isEnglishActiveLanguage,
  activeLanguage,
  activeLanguageCountryCode,
}) => {
  const { t } = useTranslation();
  const { key: activeLanguageKey, path: activeLanguagePath } = activeLanguage;

  const submitForm = (e) => {
    e.preventDefault();

    const fieldIds = [...defaultFields];
    let hubSpotFrom = null;

    const isShowChiliPiperCalendar = ChiliPiperService.isShowChiliPiperCalendar({
      companySizeRange: companySizeField.value,
      isEnglishActiveLanguage,
    });

    if (!isEnglishActiveLanguage) {
      hubSpotFrom = LOCALIZATION_PRODUCT_DEMO_HUBSPOT_FORMS[activeLanguageKey];
    } else {
      hubSpotFrom = isShowChiliPiperCalendar
        ? PRODUCT_DEMO_CHILIPIPER_HUBSPOT_FORM
        : PRODUCT_DEMO_REGULAR_HUBSPOT_FORM;
    }

    submitDemo({
      fieldIds,
      hubSpotFrom,
      isShowChiliPiperCalendar,
    });
  };

  const onClose = () => {
    window.parent.postMessage('demo_close', 'https://www.pandadoc.com');
  };

  return (
    <Styled.Wrapper>
      <Styled.Blur onClick={onClose} />
      <Styled.Form className="fcidstform" autoComplete="off" noValidate>
        <Styled.CLose onClick={onClose} />
        <Styled.Title>{t('Schedule your 15-minute demo')}</Styled.Title>
        <TextField
          label={`${t('First name')}*`}
          fieldModel={firstNameField}
          setFieldValue={setFieldValue}
          validateField={validateField}
        />
        <TextField
          label={`${t('Last name')}*`}
          fieldModel={lastNameField}
          setFieldValue={setFieldValue}
          validateField={validateField}
        />
        <TextField
          label={`${t('Work email')}*`}
          fieldModel={workEmailField}
          setFieldValue={setFieldValue}
          validateField={validateField}
          type="email"
        />
        <PhoneField
          label={`${t('Phone number')}*`}
          fieldModel={phoneNumberField}
          setFieldValue={setFieldValue}
          validateField={validateField}
          countryCode={countryCode}
          activeLanguageCountryCode={activeLanguageCountryCode}
        />
        <TextField
          label={`${t('Company name')}*`}
          fieldModel={companyNameField}
          setFieldValue={setFieldValue}
          validateField={validateField}
        />
        <SelectField
          label={`${t('Company size')}*`}
          fieldModel={companySizeField}
          setFieldValue={setFieldValue}
          validateField={validateField}
        />
        <Styled.Button onClick={submitForm}>{t('Schedule your demo')}</Styled.Button>
        {!isEnglishActiveLanguage && (
          <Styled.Disclaimer>
            {t('For certain criteria, demos are only available in English.')}
          </Styled.Disclaimer>
        )}
        <Styled.PrivacyNotice>
          <Trans t={t}>
            By submitting this form, I agree and understand the{' '}
            <Styled.Link
              href={`https://www.pandadoc.com${activeLanguagePath}/terms-of-use/`}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </Styled.Link>
            {', '}
            <Styled.Link
              href="https://drive.google.com/file/d/1fHyk7TqSO00ArHCXqzpZ6oRx1ZRN8vnf/view"
              target="_blank"
              rel="noreferrer"
            >
              DPA
            </Styled.Link>{' '}
            and{' '}
            <Styled.Link
              href={`https://www.pandadoc.com${activeLanguagePath}/privacy-notice/`}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Notice
            </Styled.Link>{' '}
            will govern the use of services I receive and the personal data I provide.
          </Trans>
        </Styled.PrivacyNotice>
      </Styled.Form>
    </Styled.Wrapper>
  );
};

RequestProductDemoOnPopupContainer.propTypes = {
  firstNameField: PropTypes.shape(fieldPropTypes).isRequired,
  lastNameField: PropTypes.shape(fieldPropTypes).isRequired,
  workEmailField: PropTypes.shape(fieldPropTypes).isRequired,
  phoneNumberField: PropTypes.shape(fieldPropTypes).isRequired,
  companyNameField: PropTypes.shape(fieldPropTypes).isRequired,
  companySizeField: PropTypes.shape(fieldPropTypes).isRequired,
  preferredLanguageField: PropTypes.shape(fieldPropTypes).isRequired,
  validateField: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitDemo: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  isEnglishActiveLanguage: PropTypes.bool.isRequired,
  activeLanguage: PropTypes.string.isRequired,
  activeLanguageCountryCode: PropTypes.string.isRequired,
};

export const RequestProductDemoOnPopup = connectToStore(
  createStructuredSelector({
    firstNameField: firstNameFieldSelector,
    lastNameField: lastNameFieldSelector,
    workEmailField: workEmailFieldSelector,
    phoneNumberField: phoneNumberFieldSelector,
    companyNameField: companyNameFieldSelector,
    companySizeField: companySizeFieldSelector,
    countryCode: countryCodeSelector,
    isEnglishActiveLanguage: isEnglishActiveLanguageSelector,
    activeLanguage: activeLanguageSelector,
    activeLanguageCountryCode: activeLanguageCountryCodeSelector,
  }),
  {
    setFieldValue: setFieldValueAction,
    validateField: validateFieldAction,
    submitDemo: submitDemoAction,
  },
)(RequestProductDemoOnPopupContainer);

import { handleActions } from 'redux-actions';
import {
  startApplication,
  setFieldValue,
  validateField,
  validateForm,
  setError,
  setLaoding,
  setCountryCode,
  setActiveLanguage,
} from './actions';
import { ApplicationModel } from './model/Application';

export const applicationReducer = handleActions(
  {
    [startApplication.receive]: (state) => state.setInitialized(),
    [setFieldValue]: (state, { payload }) => state.setFieldValue(payload),
    [validateField]: (state, { payload }) => state.validateField(payload),
    [validateForm]: (state, { payload }) => state.validateForm(payload),
    [setError]: (state, { payload }) => state.setError(payload),
    [setLaoding]: (state, { payload }) => state.setLaoding(payload),
    [setCountryCode]: (state, { payload }) => state.setCountryCode(payload),
    [setActiveLanguage]: (state, { payload }) => state.setActiveLanguage(payload),
  },
  new ApplicationModel(),
);

import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'containers/Application/model/fieldPropTypes';
import { FieldWrapper, FieldLabel, FieldError } from '../common';
import * as Styled from './styled';

export const TextField = ({
  fieldModel: { id, value, error },
  validateField,
  setFieldValue,
  label,
  type,
  fullWidth,
}) => {
  const onChange = (event) => {
    setFieldValue({
      id,
      value: event.target.value,
    });
  };

  const onBlur = () => {
    validateField({ id });
  };

  return (
    <FieldWrapper fullWidth={fullWidth}>
      <FieldLabel>{label}</FieldLabel>
      <Styled.Input
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        value={value}
        id={id}
        error={error}
      />
      {error && <FieldError>{error}</FieldError>}
    </FieldWrapper>
  );
};

TextField.propTypes = {
  fieldModel: PropTypes.shape(fieldPropTypes).isRequired,
  validateField: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
};

TextField.defaultProps = {
  type: 'text',
  fullWidth: false,
};

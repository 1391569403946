export const PROJECT_KEY = 'appjs-request-demo-web';

export const PAGES = {
  PRODUCT_DEMO: 'product-demo',
  API_DEMO: 'api-custom-request',
  POPUP_DEMO: 'popup-demo',
  ONE_YEAR_FREE: '1yearfree',
};

export const LANGUAGES_URLS = {
  ENGLISH: '',
  FRENCH: '/fr',
  SPANISH: '/es',
  NEDERLAND: '/nl',
  POLAND: '/pl',
  PORTUGUESE: '/pt-pt',
  GERMANY: '/de',
  SWEDEN: '/se',
};

export const BASE_URL = '/:locale(en|fr|es|nl|it|pl|pt-pt|de|se)?';

export const DEMO_URL = `/:page(${PAGES.API_DEMO}|${PAGES.POPUP_DEMO}|${PAGES.ONE_YEAR_FREE})?`;

export const LANGUAGE_KEYS = {
  ENGLISH: 'en-US',
  FRENCH: 'fr-FR',
  SPANISH: 'es-ES',
  NEDERLAND: 'nl-NL',
  POLAND: 'pl-PL',
  PORTUGUESE: 'pt-PT',
  GERMANY: 'de-DE',
  SWEDEN: 'sv-SE',
};

export const LANGUAGE_LOCALES = {
  ENGLISH: 'en_us',
  FRENCH: 'fr_fr',
  SPANISH: 'es_es',
  NEDERLAND: 'nl_nl',
  POLAND: 'pl_pl',
  PORTUGUESE: 'pt_pt',
  GERMANY: 'de_de',
  SWEDEN: 'sv_se',
};

export const ACTIVE_LANGUAGE_TO_PREFERRED_LANGUAGE_MAP = {
  [LANGUAGE_KEYS.ENGLISH]: 'English',
  [LANGUAGE_KEYS.FRENCH]: 'French',
  [LANGUAGE_KEYS.SPANISH]: 'Spanish',
  [LANGUAGE_KEYS.NEDERLAND]: 'Dutch',
  [LANGUAGE_KEYS.POLAND]: 'Polish',
  [LANGUAGE_KEYS.PORTUGUESE]: 'Portuguese',
  [LANGUAGE_KEYS.GERMANY]: 'German',
  [LANGUAGE_KEYS.SWEDEN]: 'Swedish',
};

export const LANGUAGES = [
  {
    label: 'English (United States)',
    key: LANGUAGE_KEYS.ENGLISH,
    path: LANGUAGES_URLS.ENGLISH,
    locale: LANGUAGE_LOCALES.ENGLISH,
  },
  {
    label: 'Français (France)',
    key: LANGUAGE_KEYS.FRENCH,
    path: LANGUAGES_URLS.FRENCH,
    locale: LANGUAGE_LOCALES.FRENCH,
  },
  {
    label: 'Español (España)',
    key: LANGUAGE_KEYS.SPANISH,
    path: LANGUAGES_URLS.SPANISH,
    locale: LANGUAGE_LOCALES.SPANISH,
  },
  {
    label: 'Nederlands (Nederland)',
    key: LANGUAGE_KEYS.NEDERLAND,
    path: LANGUAGES_URLS.NEDERLAND,
    locale: LANGUAGE_LOCALES.NEDERLAND,
  },
  {
    label: 'Polski (Polska)',
    key: LANGUAGE_KEYS.POLAND,
    path: LANGUAGES_URLS.POLAND,
    locale: LANGUAGE_LOCALES.POLAND,
  },
  {
    label: 'Português (Portugal)',
    key: LANGUAGE_KEYS.PORTUGUESE,
    path: LANGUAGES_URLS.PORTUGUESE,
    locale: LANGUAGE_LOCALES.PORTUGUESE,
  },
  {
    label: 'Deutsch (Deutschland)',
    key: LANGUAGE_KEYS.GERMANY,
    path: LANGUAGES_URLS.GERMANY,
    locale: LANGUAGE_LOCALES.GERMANY,
  },
  {
    label: 'Svenska (Sverige)',
    key: LANGUAGE_KEYS.SWEDEN,
    path: LANGUAGES_URLS.SWEDEN,
    locale: LANGUAGE_LOCALES.SWEDEN,
  },
];

export const APP_CATEGORY = 'demo';

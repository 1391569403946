import React from 'react';
import * as Styled from './styled';
import { Star } from './Star';

/* eslint-disable i18next/no-literal-string */

export const Reviews = () => {
  return (
    <Styled.Reviews>
      <Styled.Stars>
        <Star />
        <Star />
        <Star />
        <Star />
        <Star />
      </Styled.Stars>
      <Styled.Info>
        <Styled.Rating>4.7</Styled.Rating> |{' '}
        <Styled.ReviewCount>2.178 reviews</Styled.ReviewCount>
      </Styled.Info>
    </Styled.Reviews>
  );
};

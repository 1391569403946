import { defineMessage } from '@libjs-pdlocalization/components/utils';
import kickFireLogo from './images/kickFireLogo.svg';
import autodeskLogo from './images/autodeskLogo.svg';
import verifiedFirstLogo from './images/verifiedFirstLogo.svg';
import kickFireAvatar from './images/kickFireAvatar.jpeg';
import autodeskAvatar from './images/autodeskAvatar.jpeg';
import verifiedFirstAvatar from './images/verifiedFirstAvatar.jpeg';

export const STORIES = [
  {
    key: 'kickFire',
    person: {
      name: 'Maggie Taylor Aherne',
      title: defineMessage('Director of Operations'),
      avatar: kickFireAvatar,
    },
    logo: kickFireLogo,
    content: defineMessage(
      '“If I told our reps we’re going back to DocuSign, they’d probably be extremely unhappy with me. Gone are the days of our clunky, inefficient proposal and contract process.”',
    ),
  },
  {
    key: 'verifiedFirs',
    person: {
      name: 'John Gerhard',
      title: defineMessage('Information Systems Director'),
      avatar: verifiedFirstAvatar,
    },
    logo: verifiedFirstLogo,
    content: defineMessage(
      '“The time our sales team has saved with PandaDoc is incredible. Our sales team is happy that we now have a solution that has streamlined their daily tasks and has made it easier for them to close deals fast.”',
    ),
  },
  {
    key: 'autodesk',
    person: {
      name: 'Travis Evans',
      title: defineMessage('Manager of Sales Execution Strategy'),
      avatar: autodeskAvatar,
    },
    logo: autodeskLogo,
    content: defineMessage(
      '“Email is where deals go to die. Replacing our workflow with PandaDoc gave life to our sales team.”',
    ),
  },
];

import React from 'react';
import PropTypes from 'prop-types';
import { isPrerendering } from 'utils/isPrerendering';
import { Logo } from 'components/Logo';
import { LanguageDropdown } from 'components/LanguageDropdown';
import * as Styled from './styled';

export const Header = ({ showLanguageDropdown }) => {
  return (
    <Styled.Header>
      {!isPrerendering() && <Logo />}
      {showLanguageDropdown && <LanguageDropdown />}
    </Styled.Header>
  );
};

Header.propTypes = {
  showLanguageDropdown: PropTypes.bool,
};

Header.defaultProps = {
  showLanguageDropdown: false,
};

import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

export const Block = ({ children, background }) => {
  return (
    <Styled.Wrapper background={background}>
      <Styled.Content>{children}</Styled.Content>
    </Styled.Wrapper>
  );
};

Block.propTypes = {
  background: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

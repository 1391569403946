import { useEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

export const useSteps = () => {
  const [step, setStep] = useState(1);
  const autoStopIntervalRef = useRef(null);
  const pauseRef = useRef(false);
  const { ref, width } = useResizeObserver();
  const offset = width * (step - 1);

  useEffect(() => {
    if (autoStopIntervalRef.current !== null) {
      clearInterval(autoStopIntervalRef.current);
    }

    autoStopIntervalRef.current = setInterval(() => {
      if (pauseRef.current === false) {
        const nextStep = step === 1 ? 2 : 1;
        setStep(nextStep);
      }
    }, 5000);
  }, [step]);

  const pauseAutoScroll = () => {
    pauseRef.current = true;
  };
  const resumeAutoScroll = () => {
    pauseRef.current = false;
  };

  return {
    width,
    offset,
    step,
    setStep,
    ref,
    pauseAutoScroll,
    resumeAutoScroll,
  };
};

import React from 'react';
import { useBreakpoint } from '@ui-kit.next/utils/withBreakpoint';
import {
  Slide as SlideBase,
  Content,
  Quote,
  Name,
  Link,
  Signature,
  SignatureWrapper,
  StatHeader,
  StatDescription,
  Statistics,
  StatColumn,
  Position,
  ArrowIcon,
} from './styled';

/*eslint-disable i18next/no-literal-string */

export const Slide = ({ photo, logo, fullStoryLink, name, position, quote, stats }) => {
  const { downXSmall } = useBreakpoint();
  return (
    <SlideBase>
      {photo}
      <Content>
        {downXSmall && logo}
        <Quote>{quote}</Quote>
        <Link href={fullStoryLink}>
          Read full story <ArrowIcon />
        </Link>
        <SignatureWrapper>
          <Signature>
            <Name>{name}</Name>
            {position && <Position>{position}</Position>}
          </Signature>
          {!downXSmall && logo}
        </SignatureWrapper>
        <Statistics>
          {stats.map(({ header, description }, index) => {
            return (
              <StatColumn key={`${header}-${index}`}>
                <StatHeader>{header}</StatHeader>
                <StatDescription>{description}</StatDescription>
              </StatColumn>
            );
          })}
        </Statistics>
      </Content>
    </SlideBase>
  );
};

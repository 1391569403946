import styled from 'styled-components';
import Icon from '@ui-kit.next/components/Icon';
import ArrowForward from '@ui-kit.next/icons/ArrowForward.svg';
import scottHaneyPhotoSrc from './images/scott-haney.png';
import kelseyBoydPhotoSrc from './images/kelsey-boyd.png';
import scottTailorPhotoSrc from './images/scott-taylor.png';
import tateStonePhotoSrc from './images/tate-stone.png';
import tpdLogoSrc from './images/tpd.png';
import CPLogoSrc from './images/chili-piper.png';
import jonasLogoSrc from './images/jonas-club.png';
import growLogoSrc from './images/grow.png';

export const Slide = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  gap: 50px;
  background: #f8f5f3;
  padding: 60px;
  height: 100%;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    padding: 20px;
    width: 100%;
  }
`;

export const ArrowIcon = styled(Icon).attrs({
  glyph: ArrowForward,
  size: Icon.SIZE.SMALL,
})`
  display: inline-block;
`;

export const Photo = styled.img.attrs(({ src }) => ({
  width: 270,
  height: 270,
  src,
}))`
  width: 270px;
  height: 270px;

  ${({ theme }) => theme.media.down(theme.breakpoints.small, { strict: false })} {
    width: 200px;
    height: 200px;
  }

  ${({ theme }) => theme.media.down(theme.breakpoints.small)} {
    display: none !important;
  }
`;

export const ScottHaneyPhoto = styled(Photo).attrs({
  src: scottHaneyPhotoSrc,
})``;

export const KelseyBoydPhoto = styled(Photo).attrs({
  src: kelseyBoydPhotoSrc,
})``;

export const ScottTailorPhoto = styled(Photo).attrs({
  src: scottTailorPhotoSrc,
})``;

export const TateStonePhoto = styled(Photo).attrs({
  src: tateStonePhotoSrc,
})``;

export const Content = styled.div`
  color: #242424;
`;

export const Quote = styled.p`
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  margin: 0;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const Link = styled.a.attrs({
  target: '_blank',
})`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 12px;
  text-decoration: none;
  color: #242424;
`;

export const SignatureWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
`;

export const Signature = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const Position = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  opacity: 0.55;
  margin-top: 4px;
`;

export const Logo = styled.img`
  height: 44px;
  width: auto;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    margin-bottom: 20px;
  }
`;

export const TPDLogo = styled(Logo).attrs({
  src: tpdLogoSrc,
})``;

export const CPLogo = styled(Logo).attrs({
  src: CPLogoSrc,
})``;

export const JonasLogo = styled(Logo).attrs({
  src: jonasLogoSrc,
})``;

export const GrowLogo = styled(Logo).attrs({
  src: growLogoSrc,
})``;

export const Statistics = styled.div`
  border-top: 1px solid #242424;
  padding-top: 20px;
  display: flex;
  gap: 20px;
  margin-top: 40px;
  width: 100%;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    margin-top: 20px;
    flex-direction: column;
  }
`;

export const StatColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
`;

export const StatHeader = styled.p`
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  margin: 0;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall, { strict: false })} {
    font-size: 24px;
  }
`;
export const StatDescription = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
`;

import React from 'react';
import { connectToStore } from 'store';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { LANGUAGES } from 'appConstants';
import { activeLanguageSelector } from 'containers/Application/selectors';
import * as Styled from './styled';

const LanguageDropdownComponent = ({ activeLanguage }) => {
  const { page = '' } = useParams();

  const changeLanguage = (event) => {
    const languageKey = event.target.value;
    const nextLanguage = LANGUAGES.find(({ key }) => key === languageKey);

    const { origin, search } = window.location;

    let newUrl = origin + nextLanguage.path;

    if (page) {
      newUrl += '/' + page;
    }

    newUrl += search;

    window.location.href = newUrl;
  };

  return (
    <Styled.Wrapper>
      <Styled.LanguageDropdown onChange={changeLanguage} value={activeLanguage.key}>
        {LANGUAGES.map(({ key, label }) => (
          <option value={key} key={key}>
            {label}
          </option>
        ))}
      </Styled.LanguageDropdown>
    </Styled.Wrapper>
  );
};

LanguageDropdownComponent.propTypes = {
  activeLanguage: PropTypes.object.isRequired,
};

export const LanguageDropdown = connectToStore(
  createStructuredSelector({
    activeLanguage: activeLanguageSelector,
  }),
  null,
)(LanguageDropdownComponent);

import autoDeskSrc from './images/auto-desk.png';
import sgsSrc from './images/sgs.png';
import konikaSrc from './images/konika.png';
import tateSteelSrc from './images/tata-steel.png';
import bonuslySrc from './images/bonusly.png';
import tomTomSrc from './images/tom-tom.png';
import rakutenSrc from './images/rakuten.png';

const autoDesk = {
  alt: 'auto-desk partner logo',
  src: autoDeskSrc,
  width: 145,
  height: 25,
};

const sgs = {
  alt: 'sgs partner logo',
  src: sgsSrc,
  width: 73,
  height: 35,
};

const konika = {
  alt: 'konika partner logo',
  src: konikaSrc,
  width: 80,
  height: 46,
};

const tateSteel = {
  alt: 'tata steel partner logo',
  src: tateSteelSrc,
  width: 119,
  height: 18,
};

const bonusly = {
  alt: 'bonusly partner logo',
  src: bonuslySrc,
  width: 107,
  height: 33,
};

const tomTom = {
  alt: 'tom-tom partner logo',
  src: tomTomSrc,
  width: 129,
  height: 19,
};

const rakuten = {
  alt: 'rakuten partner logo',
  src: rakutenSrc,
  width: 93,
  height: 28,
};

export const CLIENTS_LOGOS = [autoDesk, sgs, konika, tateSteel, bonusly, tomTom, rakuten];

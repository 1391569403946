import { NextSlideIcon } from './NextSlideIcon';
import { NextArrowWrapper } from '../styled';
import React from 'react';

export function NextArrow(props) {
  return (
    <NextArrowWrapper {...props}>
      <NextSlideIcon fill="#242424" />
    </NextArrowWrapper>
  );
}

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import { fieldPropTypes } from 'containers/Application/model/fieldPropTypes';
import { CONTAINER_CLASS, PREFERRED_COUNTRIES } from './constants';
import { FieldLabel, FieldError } from '../common';
import * as Styled from './styled';

import 'react-phone-input-2/lib/style.css';

export const PhoneField = ({
  fieldModel: { id, value, error },
  validateField,
  setFieldValue,
  label,
  countryCode,
}) => {
  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.numberInputRef.id = id;
    // eslint-disable-next-line i18next/no-literal-string
    inputEl.current.numberInputRef.autocomplete = 'off';
    // eslint-disable-next-line i18next/no-literal-string
    inputEl.current.numberInputRef.name = 'search';
  });

  const onChange = (...args) => {
    const [, , , formattedValue] = args;

    setFieldValue({
      id,
      value: formattedValue,
    });
  };

  const onBlur = () => {
    validateField({ id });
  };

  return (
    <Styled.Wrapper error={error}>
      <FieldLabel>{label}</FieldLabel>
      <PhoneInput
        country={countryCode}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        preferredCountries={PREFERRED_COUNTRIES}
        containerClass={CONTAINER_CLASS}
        ref={inputEl}
      />
      {error && <FieldError>{error}</FieldError>}
    </Styled.Wrapper>
  );
};

PhoneField.propTypes = {
  fieldModel: PropTypes.shape(fieldPropTypes).isRequired,
  validateField: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
};

import React from 'react';
import * as Styled from './styled.js';
import { CLIENTS_LOGOS } from './constants';

/* eslint-disable i18next/no-literal-string */

export const Clients = () => {
  return (
    <Styled.Container>
      <Styled.Title>Find out why more than 50,000 customers trust PandaDoc</Styled.Title>
      <Styled.Logos>
        {CLIENTS_LOGOS.map(({ src, width, height, alt }) => (
          <Styled.Logo key={alt} src={src} width={width} height={height} alt={alt} />
        ))}
      </Styled.Logos>
    </Styled.Container>
  );
};

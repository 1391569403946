import React from 'react';
import * as Styled from '../../styled';

/* eslint-disable i18next/no-literal-string */

export const Step1 = () => {
  return (
    <div>
      <Styled.Title>What to expect from your demo:</Styled.Title>
      <Styled.List>
        <Styled.ListItem>
          <Styled.Checkmark /> Live session tailored to your business needs
        </Styled.ListItem>
        <Styled.ListItem>
          <Styled.Checkmark />
          Identifying pain points that are blocking efficiency
        </Styled.ListItem>
        <Styled.ListItem>
          <Styled.Checkmark /> Answers to all your questions about PandaDoc
        </Styled.ListItem>
        <Styled.ListItem>
          <Styled.Checkmark /> No commitment to purchase
        </Styled.ListItem>
      </Styled.List>
    </div>
  );
};

import styled from 'styled-components';

export const MobileReviewWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
    margin-top: 40px;
  }
`;

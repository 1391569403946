import { takeLatest, call, put, select, fork, all } from 'redux-saga/effects';
import { ExceptionsService } from 'libjs-pdcore/exceptions';
import { BaseSagaClass } from 'libjs-pdcore/saga/BaseSagaClass';
import Cookies from 'js-cookie';
import { isExperimentVersion } from 'utils/isExperimentVersion';
import { AnalyticsService } from 'services/AnalyticsService';
import { ChiliPiperService } from 'services/ChiliPiperService';
import { HubSpotService } from 'services/HubSpotService';
import { APIService } from 'services/APIService';
import { DriftService } from 'services/DriftService';
import { identifyEmailWithClearbit } from '../../utils/identifyEmailWithClearbit';
import {
  startApplication,
  setLaoding,
  validateForm,
  setError,
  submitDemo,
  setCountryCode,
  initializeForm,
  setFieldValue,
} from './actions';
import {
  applicationConfigSelector,
  makeIsFormHasErrorsSelector,
  companySizeFieldSelector,
  fieldsSelector,
  activeLanguageSelector,
  workEmailFieldSelector,
  activeLanguageKeySelector,
} from './selectors';
import {
  FIELDS,
  MAP_ERRORS_TYPE_TO_FIELD,
  TRACK_REQUEST_DEMO,
  DEFAULT_COUNTRY_CODE,
  NOT_VALID_EMAIL_ERROR,
  NOT_BUSINESS_EMAIL_ERROR,
} from './constants';

import { ACTIVE_LANGUAGE_TO_PREFERRED_LANGUAGE_MAP } from '../../appConstants';

export class ApplicationSagaWorker extends BaseSagaClass {
  static *startApplication(action) {
    try {
      const applicationConfig = yield select(applicationConfigSelector);

      yield call(ExceptionsService.init, {
        dsn: applicationConfig.RAVEN_URL,
        environment: applicationConfig.ENVIRONMENT,
        release: 'master', //TO DO: Throw release from gitlab
        application: applicationConfig.SENTRY_PROJECT_KEY,
      });

      yield call(AnalyticsService.init, {
        isSegmentEnabled: applicationConfig.IS_SEGMENTIO_ENABLED,
        segmentKey: applicationConfig.SEGMENTIO_KEY,
        jitsuConfig: {
          isEnabled: applicationConfig.JITSU_ENABLED,
          key: applicationConfig.JITSU_AUTH_KEY,
          host: applicationConfig.JITSU_HOST,
          cookieDomain: applicationConfig.JITSU_COOKIE_DOMAIN,
        },
      });

      yield call(ChiliPiperService.init, {
        isEnabled: applicationConfig.IS_CHILI_PIPER_ENABLED,
      });

      yield call(APIService.init, {
        backendUrl: applicationConfig.API_BASE_URL,
      });

      if (isExperimentVersion) {
        yield call(DriftService.init, {
          isEnabled: applicationConfig.IS_DRIFT_ENABLED,
        });
      }

      const { country_code } = yield call(APIService.getCountryCode);

      if (!country_code) {
        // eslint-disable-next-line i18next/no-literal-string
        ExceptionsService.captureMessage('Failed detection of the country');
      }

      yield put(setCountryCode(country_code || DEFAULT_COUNTRY_CODE));
      yield put(initializeForm());

      yield put(startApplication.receive());
    } catch (error) {
      yield fork(ApplicationSagaWorker.catchError, error, {
        saga: 'ApplicationSagaWorker/startApplication',
        action,
      });
    }
  }

  static *validateEmail(action) {
    try {
      const { value: email } = yield select(workEmailFieldSelector);

      const { emailFree, success } = yield call(APIService.validateEmail, { email });

      let error;

      if (emailFree) {
        error = NOT_BUSINESS_EMAIL_ERROR;
      }

      if (!success) {
        error = NOT_VALID_EMAIL_ERROR;
      }

      if (error) {
        yield call(AnalyticsService.track, TRACK_REQUEST_DEMO.error_appeared, {
          user_email: email,
          error_message: emailFree ? 'Personal email error' : 'Invalid email error',
        });

        ExceptionsService.captureMessage(error, {
          extra: {
            Email: email,
          },
        });

        yield put(setError({ id: FIELDS.EMAIL, error }));
      }
    } catch (error) {
      yield fork(ApplicationSagaWorker.catchError, error, {
        saga: 'ApplicationSagaWorker/validateEmail',
        action,
      });
    }
  }

  static *growsumoCreateSignup({ firstName, email }) {
    if (window.growsumo && Cookies.get('growSumoPartnerKey')) {
      window.growsumo.data.name = firstName;
      window.growsumo.data.email = email;
      window.growsumo.data.customer_key = email;

      yield call(window.growsumo.createSignup);
    }
  }

  static *initializeForm() {
    const activeLanguage = yield select(activeLanguageKeySelector);

    const preferredLanguage = ACTIVE_LANGUAGE_TO_PREFERRED_LANGUAGE_MAP[activeLanguage];
    yield put(setFieldValue({ id: FIELDS.PREFERRED_LANGUAGE, value: preferredLanguage }));
  }

  static *submitDemo(action) {
    try {
      const {
        hubSpotFrom,
        fieldIds,
        isShowChiliPiperCalendar = false,
        isRaffle = false,
        router,
      } = action.payload;

      yield put(setLaoding(true));
      yield put(validateForm({ fieldIds }));
      yield call(ApplicationSagaWorker.validateEmail);

      const isValidForm = yield select(makeIsFormHasErrorsSelector({ fieldIds }));

      if (isValidForm) {
        const fields = yield select(fieldsSelector);

        yield call(AnalyticsService.track, TRACK_REQUEST_DEMO.submission_attempted, {
          user_email: fields[FIELDS.EMAIL].value,
        });

        const modifiedFields = fieldIds.map((id) => ({
          name: id,
          value: fields[id].value,
        }));

        const dataForHubspot = yield call(HubSpotService.getDataToSend, { isRaffle });

        const form = {
          guid: hubSpotFrom.GUID,
        };

        const response = yield call(APIService.requestDemo, {
          fields: [...modifiedFields, ...dataForHubspot],
          form,
        });

        if (response.errors) {
          yield call(ApplicationSagaWorker.handleErrors, { errors: response.errors });
        } else {
          const email = fields[FIELDS.EMAIL].value;
          const firstName = fields[FIELDS.FIRST_NAME].value;
          yield call(ApplicationSagaWorker.growsumoCreateSignup, { firstName, email });

          const { value: companySizeRange } = yield select(companySizeFieldSelector);

          yield fork(identifyEmailWithClearbit, email);
          yield call(AnalyticsService.track, TRACK_REQUEST_DEMO.demo_requested, {
            company_size: companySizeRange,
            email,
            source: 'request_demo',
          });

          if (isExperimentVersion) {
            yield call(ApplicationSagaWorker.sendGTMEvent);
          }

          yield put(setLaoding(false));

          if (isShowChiliPiperCalendar) {
            yield call(ChiliPiperService.showCalendar, {
              fields: [...modifiedFields, ...dataForHubspot],
              router,
            });
          } else {
            const { path } = yield select(activeLanguageSelector);

            const successUrl =
              // eslint-disable-next-line i18next/no-literal-string
              'https://www.pandadoc.com' + path + '/thanks' + window.location.search;

            window.location.replace(successUrl);
          }
        }
      } else {
        yield put(setLaoding(false));
      }
    } catch (error) {
      yield put(setLaoding(false));
      yield fork(ApplicationSagaWorker.catchError, error, {
        saga: 'ApplicationSagaWorker/requestDemo',
        action,
      });
    }
  }

  static *handleErrors(action) {
    try {
      const { errors } = action;

      yield all(
        errors.reduce((effects, { errorType }) => {
          const field = MAP_ERRORS_TYPE_TO_FIELD[errorType];

          if (field?.id) {
            return [...effects, put(setError({ id: field.id, error: field.error }))];
          }

          return effects;
        }, []),
      );

      yield put(setLaoding(false));
    } catch (error) {
      yield fork(ApplicationSagaWorker.catchError, error, {
        saga: 'ApplicationSagaWorker/handleErrors',
        action,
      });
    }
  }

  static *sendGTMEvent() {
    window.dataLayer = window.dataLayer || [];

    yield call(window.dataLayer.push, {
      // eslint-disable-next-line i18next/no-literal-string
      event: 'requested a demo',
    });
  }
}

export function* applicationSagaWatcher() {
  yield takeLatest(startApplication.request, ApplicationSagaWorker.startApplication);
  yield takeLatest(submitDemo, ApplicationSagaWorker.submitDemo);
  yield takeLatest(initializeForm, ApplicationSagaWorker.initializeForm);
}

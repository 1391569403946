import React, { useEffect, lazy, Suspense, useMemo, useCallback } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { injectSaga, injectReducer, connectToStore } from 'store';
import { createStructuredSelector } from 'reselect';
import { isPrerendering } from 'utils/isPrerendering';
import { LANGUAGES, PAGES } from 'appConstants';
import { Progress } from 'components/Progress';
import { HtmlHead } from 'components/HtmlHead';
import { applicationSagaWatcher } from './saga';
import {
  startApplication as startApplicationAction,
  setActiveLanguage as setActiveLanguageAction,
} from './actions';
import { STORE_KEY } from './constants';
import { applicationReducer } from './reducer';
import { isLoadingSelector } from './selectors';
import { RequestProductDemo } from '../RequestProductDemo';
import { RequestApiDemo } from '../RequestApiDemo';
import { RequestProductDemoOnPopup } from '../RequestProductDemoOnPopup';
import { RequestProductDemoRaffle } from '../RequestProductDemoRaffle';
import { getLocationQuery } from '../../utils/getLocationQuery';
import Cookies from 'js-cookie';

const ExitPopup = lazy(() => {
  return import(
    /* webpackChunkName: "ExitDemo" */
    '../ExitPopup'
  ).then((module) => ({
    default: module.ExitPopup,
  }));
});

export const ApplicationContainer = ({
  setActiveLanguage,
  startApplication,
  isLoading,
}) => {
  const { locale = '', page } = useParams();
  const activeLanguage =
    LANGUAGES.find(({ path }) => path === `/${locale}`) || LANGUAGES[0]; //English is default;

  const shouldHideExitPopup = useMemo(
    () =>
      true ||
      Boolean(Cookies.get('_gaexp')) ||
      getLocationQuery()['ssd-popup-hide'] === 'true',
    [],
  );
  const getExitPopup = useCallback(
    () => (shouldHideExitPopup ? null : <ExitPopup />),
    [shouldHideExitPopup],
  );

  useEffect(() => {
    setActiveLanguage({ language: activeLanguage });

    if (!isPrerendering()) {
      startApplication();
    }
  }, []);

  const getPage = () => {
    if (page === PAGES.API_DEMO) {
      return <RequestApiDemo />;
    }

    if (page === PAGES.POPUP_DEMO) {
      return <RequestProductDemoOnPopup />;
    }

    if (page === PAGES.ONE_YEAR_FREE) {
      return <RequestProductDemoRaffle />;
    }

    return <RequestProductDemo />;
  };

  return (
    <>
      {isLoading && <Progress />}
      <HtmlHead locale={activeLanguage.locale} page={page} />
      <Suspense fallback={null}>{getExitPopup()}</Suspense>
      {getPage()}
    </>
  );
};

ApplicationContainer.propTypes = {
  startApplication: PropTypes.func.isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export const Application = compose(
  injectSaga({ key: STORE_KEY, saga: applicationSagaWatcher }),
  injectReducer({ key: STORE_KEY, reducer: applicationReducer }),
  connectToStore(
    createStructuredSelector({
      isLoading: isLoadingSelector,
    }),
    {
      startApplication: startApplicationAction.request,
      setActiveLanguage: setActiveLanguageAction,
    },
  ),
)(ApplicationContainer);

import styled from 'styled-components';
import { CommonInputStyles } from '../common';

export const Select = styled.select`
  ${CommonInputStyles}
  appearance: none;
`;

export const Svg = styled.svg`
  position: absolute;
  right: 12px;
  top: 42px;
  pointer-events: none;
`;

import {
  CPLogo,
  GrowLogo,
  JonasLogo,
  KelseyBoydPhoto,
  ScottHaneyPhoto,
  ScottTailorPhoto,
  TateStonePhoto,
  TPDLogo,
} from './styled';
import React from 'react';

export const testimonials = [
  {
    photo: <ScottHaneyPhoto />,
    logo: <CPLogo />,
    fullStoryLink: 'https://www.pandadoc.com/customers-case-study/chili-piper/',
    name: 'Scott Haney',
    quote:
      '”Thanks to the integrations, payment functionality, and other time-saving features of PandaDoc, Chili Piper’s internal processes became as streamlined as the product they were selling.”',
    stats: [
      {
        header: '15',
        description: 'minutes saved per contract',
      },
      {
        header: '28%',
        description: 'increase in close rates',
      },
      {
        header: '12',
        description: 'hours per week',
      },
    ],
  },
  {
    photo: <KelseyBoydPhoto />,
    logo: <TPDLogo />,
    fullStoryLink: 'https://www.pandadoc.com/customers-case-study/tpd/',
    name: 'Kelsey Boyd',
    position: 'Director of Business Systems & Processes',
    quote:
      '”Having approved content and standardized workflows in place, allows our people to optimize their use of time! PandaDoc is making this so much more efficient than it once was. The Hiring Packages are a breeze to send to candidates… this would’ve been a time-consuming administrative burden if it weren’t for PandaDoc.”',
    stats: [
      {
        header: '$3,700',
        description: 'saved per month',
      },
      {
        header: '188',
        description: 'hours saved per month',
      },
      {
        header: '75%',
        description: 'decreased onboarding time',
      },
    ],
  },
  {
    photo: <TateStonePhoto />,
    logo: <GrowLogo />,
    fullStoryLink: 'https://www.pandadoc.com/customers-case-study/grow/',
    name: 'Tate Stone',
    position: 'Head of Technical & Revenue Operations',
    quote:
      '”The biggest differentiator with PandaDoc is we loved how slick it looked and how easy it is to use. With the other tools, it felt like you were signing divorce papers which is not a great impression when you’re trying to establish a new business relationship.”',
    stats: [
      {
        header: '75%',
        description: 'less time to get a document out',
      },
      {
        header: '188',
        description: 'hours saved per month',
      },
      {
        header: '75%',
        description: 'decreased onboarding time',
      },
    ],
  },
  {
    photo: <ScottTailorPhoto />,
    logo: <JonasLogo />,
    fullStoryLink: 'https://www.pandadoc.com/customers-case-study/jonas-club-software/',
    name: 'Scott Taylor',
    position: 'Director of Sales',
    quote:
      '”Our experience from day 1 has been absolutely amazing, we’ve thoroughly enjoyed everyone we’ve worked with at PandaDoc. We’ve only ever had to submit a handful of support tickets which speaks to how well the product just works. We truly feel like we’re the only client PandaDoc has.”',
    stats: [
      {
        header: '32',
        description: 'hours saved per week',
      },
      {
        header: '23%',
        description: 'increase in close rate',
      },
      {
        header: '30%',
        description: 'increase in doc accuracy',
      },
    ],
  },
];

import React from 'react';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import * as Styled from './styled';
import badges from './images/badges.svg';

export const Intro = () => {
  const { t } = useTranslation();

  return (
    <Styled.Intro>
      <Styled.Title>{t('Speak with a Solution Expert')}</Styled.Title>
      <Styled.Content>
        {t(
          'Get a complete understanding of how PandaDoc API fits your needs, from customizations to building applications in real time.',
        )}
      </Styled.Content>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <Styled.Image src={badges} alt="g2crowd" />
    </Styled.Intro>
  );
};

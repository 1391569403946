import { ROUTER_NAME, WORKSPACE, MAP_CHILIPIPER_FIELDS_ID } from './constants';

const getChiliPiperScript = () => {
  /* eslint-disable */
  !(function () {
    var n = document.createElement('script');
    n.type = 'text/javascript';
    n.async = true;
    n.src = 'https://js.chilipiper.com/marketing.js';
    var a = document.getElementsByTagName('script')[0];
    a.parentNode.insertBefore(n, a);
  })();
  /* eslint-enable */
};

class ChiliPiper {
  constructor() {
    this.isEnabled = false;
  }

  init = ({ isEnabled }) => {
    this.isEnabled = isEnabled;

    if (this.isEnabled) {
      getChiliPiperScript();
    }
  };

  companySizeMoreThan = (companySizeRange, targetValue = 2) => {
    const [leftBorder] = companySizeRange.split('-');

    return parseInt(leftBorder) >= targetValue;
  };

  isShowChiliPiperCalendar = ({ companySizeRange, isEnglishActiveLanguage }) => {
    const companySizeRequirement = isEnglishActiveLanguage ? 2 : 11;

    return (
      this.isEnabled &&
      window.ChiliPiper &&
      this.companySizeMoreThan(companySizeRange, companySizeRequirement)
    );
  };

  showCalendar = ({ fields, router = ROUTER_NAME }) => {
    const lead = {};
    fields.forEach((field) => {
      const id = MAP_CHILIPIPER_FIELDS_ID[field.name];

      if (id) {
        lead[id] = field.value;
      }
    });
    window.ChiliPiper.submit(WORKSPACE, router, { map: true, lead });
  };
}

const ChiliPiperService = new ChiliPiper();

export { ChiliPiperService };

import PropTypes from 'prop-types';
import { Button } from './styled';

/* eslint-disable i18next/no-literal-string */

export const Prev = ({ fill, disabled, onClick }) => (
  <Button onClick={onClick} disabled={disabled} className="prev">
    <svg
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5713 18.3337L9.11529 19.9474L0.157576 9.97943L9.081 0.0480043L10.537 1.66172L3.06501 9.97943L10.5713 18.3337Z"
        fill={fill}
        fillOpacity={disabled ? 0.6 : 1}
      />
    </svg>
  </Button>
);

Prev.propTypes = {
  onClick: PropTypes.func.isRequired,
  fill: PropTypes.string,
  disabled: PropTypes.bool,
};
Prev.defaultProps = {
  fill: '#FFF',
  disabled: false,
};

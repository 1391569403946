import styled from 'styled-components';
import { Wrapper } from '../Intro/styled';

export const Reviews = styled.div`
  display: flex;
  gap: 10px;

  ${Wrapper} & {
    margin-top: 36px;
  }

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    ${Wrapper} & {
      display: none;
    }
  }
`;

export const Stars = styled.div`
  display: flex;
  gap: 4px;
`;

export const Info = styled.div`
  display: flex;
  gap: 8px;
  color: #242424;
`;

export const Rating = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const ReviewCount = styled.a.attrs({
  href: 'https://www.g2.com/products/pandadoc/reviews',
  target: '_blank',
})`
  color: #47b972;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
`;

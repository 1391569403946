import React from 'react';
import { useParams } from 'react-router-dom';

export const Logo = () => {
  // eslint-disable-next-line i18next/no-literal-string
  const url = new URL('https://www.pandadoc.com/' + window.location.search);

  const { locale = '' } = useParams();

  url.pathname = locale;

  return (
    <a href={url.href} role="link">
      <svg
        width="129"
        height="30"
        viewBox="0 0 129 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="30" height="30" fill="#47B972" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9958 10.9877L21.9202 11.0257C21.2773 10.4933 20.4454 10.151 19.3866 10.151C18.0252 10.151 16.7773 10.6454 15.9454 11.6342L17.2311 13.0034C17.874 12.3949 18.5546 12.0526 19.4244 12.0526C21.0504 12.0906 22.1092 13.3456 22.1092 15.019C22.1092 16.6924 21.0126 17.9855 19.3487 17.9855C15.1891 17.9855 16.3613 10.151 10.7647 10.151C7.96639 10.151 6 12.2427 6 15.019V23.5H8.0042V18.9743L8.07983 18.9362C8.72269 19.4687 9.55462 19.811 10.6134 19.811C11.9748 19.811 13.2227 19.3166 14.0546 18.3277L12.7689 16.9586C12.1261 17.5671 11.4454 17.9094 10.5756 17.9094C8.94958 17.8714 7.89076 16.6163 7.89076 14.943C7.89076 13.2696 8.98739 11.9765 10.6513 11.9765C14.8109 11.9765 13.6387 19.811 19.2353 19.811C22.0336 19.811 24 17.7192 24 14.943V6.5H21.9958V10.9877Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.2084 7V12.7126C76.604 11.908 75.6369 11.3851 74.3474 11.3851C71.8087 11.3851 69.7939 13.3966 69.7939 16.1724C69.7939 18.9483 71.8087 20.9598 74.3474 20.9598C75.6369 20.9598 76.5637 20.4368 77.2084 19.6322V20.7184H79.2233V7H77.2084ZM71.8087 16.1724C71.8087 17.7816 72.8564 19.1092 74.5489 19.1092C76.201 19.1092 77.289 17.8621 77.289 16.1724C77.289 14.523 76.201 13.2356 74.5489 13.2356C72.8564 13.2356 71.8087 14.5632 71.8087 16.1724Z"
          fill="#242424"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.176 15.5287V20.7184H38V7.4023H43.1982C46.3011 7.4023 47.9532 8.97126 47.9532 11.4655C47.9532 13.9598 46.3011 15.5287 43.1982 15.5287H40.176ZM40.176 9.41379V13.4368H43.1579C44.8907 13.4368 45.6966 12.7931 45.6966 11.4253C45.6966 10.0575 44.8907 9.41379 43.1579 9.41379H40.176Z"
          fill="#242424"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.8258 20.7184H55.811V19.6322C55.1663 20.4368 54.2395 20.9598 52.95 20.9598C50.4113 20.9598 48.3965 18.9483 48.3965 16.1724C48.3965 13.3966 50.4113 11.3851 52.95 11.3851C54.2395 11.3851 55.2066 11.908 55.811 12.7126V11.6264H57.8258V20.7184ZM53.1515 19.1092C51.459 19.1092 50.4113 17.7816 50.4113 16.1724C50.4113 14.5632 51.459 13.2356 53.1515 13.2356C54.8036 13.2356 55.8916 14.523 55.8916 16.1724C55.8916 17.8621 54.8036 19.1092 53.1515 19.1092Z"
          fill="#242424"
        />
        <path
          d="M68.5044 15.0057V20.7586H66.4896V15.6494C66.4896 14.1207 65.6836 13.2759 64.3539 13.2759C63.0644 13.2759 61.9764 14.1207 61.9764 15.6897V20.7586H59.9616V11.6264H61.9361V12.7529C62.6614 11.6667 63.7897 11.3851 64.7568 11.3851C66.9731 11.3851 68.5044 12.8736 68.5044 15.0057Z"
          fill="#242424"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.1691 20.7184H90.1839V11.6264H88.1691V12.7126C87.5646 11.908 86.5975 11.3851 85.308 11.3851C82.7693 11.3851 80.7545 13.3966 80.7545 16.1724C80.7545 18.9483 82.7693 20.9598 85.308 20.9598C86.5975 20.9598 87.5243 20.4368 88.1691 19.6322V20.7184ZM82.7693 16.1724C82.7693 17.7816 83.817 19.1092 85.5095 19.1092C87.1616 19.1092 88.2497 17.8621 88.2497 16.1724C88.2497 14.523 87.1616 13.2356 85.5095 13.2356C83.817 13.2356 82.7693 14.5632 82.7693 16.1724Z"
          fill="#242424"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M104.691 14.0805C104.691 10.0575 101.95 7.4023 97.7596 7.4023H92.4405V20.7586H97.7596C101.95 20.7586 104.691 18.1034 104.691 14.0805ZM94.6165 9.45402V18.7069H97.7596C100.903 18.7069 102.434 16.7759 102.434 14.0805C102.434 11.3448 100.903 9.45402 97.7596 9.45402H94.6165Z"
          fill="#242424"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.654 20.9598C113.475 20.9598 115.571 18.908 115.571 16.1724C115.571 13.4368 113.475 11.3851 110.654 11.3851C107.834 11.3851 105.738 13.4368 105.738 16.1724C105.738 18.908 107.834 20.9598 110.654 20.9598ZM107.793 16.1724C107.793 17.9023 109.043 19.1092 110.654 19.1092C112.307 19.1092 113.516 17.9023 113.516 16.1724C113.516 14.4425 112.266 13.2356 110.654 13.2356C109.043 13.2356 107.793 14.4425 107.793 16.1724Z"
          fill="#242424"
        />
        <path
          d="M123.67 14.2011L124.96 12.8333C124.154 11.8678 122.905 11.3851 121.575 11.4253C118.673 11.4253 116.78 13.477 116.78 16.2126C116.78 18.9483 118.673 21 121.575 21C122.945 21 124.154 20.5172 125 19.5517L123.751 18.1839C123.146 18.7471 122.381 19.1494 121.494 19.1494C119.963 19.1494 118.794 17.9425 118.794 16.2126C118.794 14.4828 119.963 13.2759 121.494 13.2759C122.34 13.2759 123.025 13.5977 123.67 14.2011Z"
          fill="#242424"
        />
        <path
          d="M126.315 6.98643H127.009C127.237 6.98643 127.42 7.03469 127.559 7.13122C127.697 7.22775 127.766 7.37858 127.766 7.58371V7.59276C127.766 7.74359 127.727 7.86124 127.649 7.9457C127.577 8.03017 127.48 8.0905 127.36 8.1267L127.883 8.96833H127.432L126.955 8.19909H126.748V8.96833H126.315V6.98643ZM127 7.90045C127.228 7.90045 127.342 7.80392 127.342 7.61086V7.60181C127.342 7.49925 127.312 7.42685 127.252 7.38462C127.192 7.34238 127.108 7.32127 127 7.32127H126.748V7.90045H127ZM127 9.67421C127.234 9.67421 127.45 9.63499 127.649 9.55656C127.847 9.4721 128.018 9.35747 128.162 9.21267C128.312 9.06184 128.429 8.88386 128.514 8.67873C128.598 8.4736 128.64 8.24736 128.64 8C128.64 7.75264 128.598 7.5264 128.514 7.32127C128.435 7.11614 128.324 6.94118 128.18 6.79638C128.036 6.64555 127.862 6.53092 127.658 6.45249C127.459 6.36802 127.24 6.32579 127 6.32579C126.76 6.32579 126.538 6.36802 126.333 6.45249C126.135 6.53092 125.964 6.64555 125.82 6.79638C125.676 6.94721 125.562 7.12519 125.477 7.33032C125.399 7.53545 125.36 7.76169 125.36 8.00905C125.36 8.25641 125.402 8.48265 125.486 8.68778C125.571 8.89291 125.685 9.06787 125.829 9.21267C125.979 9.35747 126.153 9.4721 126.351 9.55656C126.556 9.63499 126.772 9.67421 127 9.67421ZM127 10C126.724 10 126.462 9.94872 126.216 9.84615C125.976 9.74359 125.766 9.60483 125.586 9.42986C125.405 9.24887 125.261 9.03771 125.153 8.79638C125.051 8.55505 125 8.29261 125 8.00905C125 7.73152 125.051 7.4721 125.153 7.23077C125.255 6.98341 125.396 6.76923 125.577 6.58824C125.757 6.40724 125.967 6.26546 126.207 6.1629C126.453 6.0543 126.718 6 127 6C127.282 6 127.544 6.05128 127.784 6.15385C128.03 6.25641 128.243 6.39819 128.423 6.57919C128.604 6.76018 128.745 6.97436 128.847 7.22172C128.949 7.46305 129 7.72247 129 8C129 8.28356 128.946 8.54902 128.838 8.79638C128.736 9.03771 128.595 9.24887 128.414 9.42986C128.234 9.60483 128.021 9.74359 127.775 9.84615C127.535 9.94872 127.276 10 127 10Z"
          fill="#242424"
        />
      </svg>
    </a>
  );
};

/* eslint-disable i18next/no-literal-string */
import styled, { css } from 'styled-components';

export const FieldLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 6px;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const FieldWrapper = styled.div`
  height: auto;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(50% - 10px)')};
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const FieldError = styled.div`
  width: 100%;
  font-size: 12px;
  color: #e44e48;
  margin-top: 3px;
  text-align: left;
`;

export const CommonInputStyles = css`
  outline: none;
  border-radius: 0px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 7px 10px 8px;
  box-sizing: border-box;
  font-family: Graphik, -apple-system, system-ui, system-ui, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  background-color: #ffffff;

  &::-ms-reveal {
    display: none;
  }

  &::-ms-clear {
    display: none;
  }

  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  border: 1px solid ${(props) => (props.error ? 'rgba(228, 78, 72, 0.5)' : '#DFDCDA')};

  @media (hover: hover) {
    &:hover {
      border: 1px solid ${(props) => (props.error ? '#E44E48' : 'rgba(36, 36, 36, 0.3)')};
    }
  }

  &:focus {
    border: 1px solid ${(props) => (props.error ? '#E44E48' : '#47B972')};
  }
`;

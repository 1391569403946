import styled from 'styled-components';

export const Clients = styled.div`
  max-width: 851px;
  margin: auto;

  @media (max-width: 992px) {
    max-width: 476px;
  }
`;

export const Title = styled.div`
  color: #242424;
  font-weight: 600;
  font-size: 18px;
  margin-top: 73px;
  text-align: center;
`;

export const Picture = styled.picture`
  display: block;
  margin: 40px auto;

  img {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 0 62px;
  }
`;

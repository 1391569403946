import React from 'react';
import * as Styled from '../../styled';

/* eslint-disable i18next/no-literal-string */

export const Step2 = () => {
  return (
    <div>
      <Styled.Title>The results speak for themselves:</Styled.Title>
      <Styled.Paragraph>
        <Styled.Bold>87%</Styled.Bold> increase in closed deals per month
      </Styled.Paragraph>
      <Styled.Paragraph>
        <Styled.Bold>54%</Styled.Bold> increase in documents sent per month
      </Styled.Paragraph>
      <Styled.Paragraph>
        <Styled.Bold>50%</Styled.Bold> decrease in document creation time
      </Styled.Paragraph>
    </div>
  );
};

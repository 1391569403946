import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    display: block;
    margin-top: 50px;
  }
`;

import PropTypes from 'prop-types';
import { Button } from './styled';

/* eslint-disable i18next/no-literal-string */
export const Next = ({ fill, disabled, onClick }) => (
  <Button onClick={onClick} disabled={disabled} className="next">
    <svg
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.428711 1.66628L1.88471 0.0525665L10.8424 10.0206L1.919 19.952L0.462996 18.3383L7.93499 10.0206L0.428711 1.66628Z"
        fill={fill}
        fillOpacity={disabled ? 0.6 : 1}
      />
    </svg>
  </Button>
);

Next.propTypes = {
  onClick: PropTypes.func.isRequired,
  fill: PropTypes.string,
  disabled: PropTypes.bool,
};

Next.defaultProps = {
  fill: '#FFF',
  disabled: false,
};

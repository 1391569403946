import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import { DEFAULT_COUNTRY_CODE } from 'libjs-pdcomponents/fields/PhoneField/constants';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { fieldPropTypes } from 'containers/Application/model/fieldPropTypes';
import { CONTAINER_CLASS, PREFERRED_COUNTRIES } from './constants';
import { FieldError } from '../common';
import * as Styled from './styled';
import { usePhoneCountryLocalization } from './hooks/usePhoneCountryLocalization';

import 'react-phone-input-2/lib/style.css';

export const PhoneField = ({
  fieldModel: { error, id, value },
  setFieldValue,
  validateField,
  label,
  countryCode,
  activeLanguageCountryCode,
  fullWidth,
}) => {
  const { t } = useTranslation();
  const localizationData = usePhoneCountryLocalization();

  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.numberInputRef.id = id;
    // eslint-disable-next-line i18next/no-literal-string
    inputEl.current.numberInputRef.autocomplete = 'off';
    // eslint-disable-next-line i18next/no-literal-string
    inputEl.current.numberInputRef.name = 'search';
  });

  const onChange = (...args) => {
    const [, , , formattedValue] = args;

    setFieldValue({ id, value: formattedValue });
  };

  const onBlur = () => {
    validateField({ id });
  };

  return (
    <Styled.Wrapper error={error} fullWidth={fullWidth}>
      <PhoneInput
        country={countryCode || DEFAULT_COUNTRY_CODE}
        localization={localizationData.localization}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        preferredCountries={PREFERRED_COUNTRIES[activeLanguageCountryCode]}
        containerClass={CONTAINER_CLASS}
        ref={inputEl}
        key={localizationData.forceRerenderKey}
      />
      <Styled.PhoneActiveLabel>{t(label)}</Styled.PhoneActiveLabel>
      {error && <FieldError>{t(error)}</FieldError>}
    </Styled.Wrapper>
  );
};

PhoneField.propTypes = {
  fieldModel: PropTypes.shape(fieldPropTypes).isRequired,
  validateField: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
};

PhoneField.defaultProps = {
  fullWidth: false,
};

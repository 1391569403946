import styled from 'styled-components';
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    min-height: 60px;
  }
`;

export const Button = styled.button`
  display: block;
  color: #fff;
  background-color: #47b972;
  padding: 9px 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.25s;
  height: 34px;

  &:hover {
    background-color: #43af6c;
  }

  ${Header} & {
    display: none;
  }

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    ${Header} & {
      display: block;
    }
  }
`;

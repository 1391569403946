import React from 'react';
import * as Styled from './styled.js';
import { Reviews } from '../Reviews';

/* eslint-disable i18next/no-literal-string */
export const Intro = () => {
  return (
    <Styled.Wrapper>
      <Styled.Title>Save time and maximize ROI with PandaDoc</Styled.Title>
      <Reviews />
    </Styled.Wrapper>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { fieldPropTypes } from 'containers/Application/model/fieldPropTypes';
import {
  FIELDS,
  COMPANY_SIZES,
  PREFERRED_LANGUAGE_ANSWERS,
} from 'containers/Application/constants';
import { Label, ActiveLabel, FieldError, FieldWrapper } from '../common';
import * as Styled from './styled';

const chooseOfOptions = {
  [FIELDS.COMPANY_SIZE]: COMPANY_SIZES,
  [FIELDS.PREFERRED_LANGUAGE]: PREFERRED_LANGUAGE_ANSWERS,
};

export const SelectField = ({
  fieldModel: { id, error, value },
  label,
  setFieldValue,
  validateField,
  fullWidth,
}) => {
  const { t } = useTranslation();

  const onChange = (event) => {
    setFieldValue({
      id,
      value: event.target.value,
    });
  };

  const onBlur = () => {
    validateField({ id });
  };

  const renderOptions = () =>
    chooseOfOptions[id].map((option) => (
      <option key={option.value} value={option.value}>
        {t(option.label)}
      </option>
    ));

  return (
    <FieldWrapper fullWidth={fullWidth}>
      <Styled.Svg xmlns="http://www.w3.org/2000/svg" width="9" height="6">
        <path d="M7.73 0l1 1-4.37 4.36L0 1l1-1 3.36 3.36z" fill="#888" />
      </Styled.Svg>
      <Styled.Select
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={error}
      >
        <option value="" hidden></option>
        {renderOptions()}
      </Styled.Select>
      {value ? <ActiveLabel>{t(label)}</ActiveLabel> : <Label>{t(label)}</Label>}
      {error && <FieldError>{t(error)}</FieldError>}
    </FieldWrapper>
  );
};

SelectField.propTypes = {
  fieldModel: PropTypes.shape(fieldPropTypes).isRequired,
  label: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

SelectField.defaultProps = {
  fullWidth: false,
};

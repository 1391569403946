import { Record, Map, fromJS } from 'immutable';
import { appConfig } from 'appConfig';
import { LANGUAGES } from 'appConstants';
import { createRequestFormModel } from './createRequestFormModel';

const defaultState = {
  isInitialized: false,
  appConfig,
  requestForm: createRequestFormModel(),
  countryCode: '',
  activeLanguage: new Map(LANGUAGES[0]),
};

export class ApplicationModel extends Record(defaultState) {
  setActiveLanguage({ language }) {
    return this.set('activeLanguage', fromJS(language));
  }

  setInitialized() {
    return this.set('isInitialized', true);
  }

  setFieldValue({ id, value }) {
    const requestForm = this.get('requestForm');

    return this.set('requestForm', requestForm.setFieldValue({ id, value }));
  }

  validateField({ id }) {
    const requestForm = this.get('requestForm');

    return this.set('requestForm', requestForm.validateField({ id }));
  }

  validateForm({ fieldIds }) {
    const requestForm = this.get('requestForm');

    return this.set('requestForm', requestForm.validateForm({ fieldIds }));
  }

  isFormValid({ fieldIds }) {
    const requestForm = this.get('requestForm');

    return requestForm.isFormValid({ fieldIds });
  }

  setError({ id, error }) {
    const requestForm = this.get('requestForm');

    return this.set('requestForm', requestForm.setError({ id, error }));
  }

  setLaoding(isLoading) {
    const requestForm = this.get('requestForm');

    return this.set('requestForm', requestForm.setLaoding(isLoading));
  }

  setCountryCode(countryCode) {
    return this.set('countryCode', countryCode.toLowerCase());
  }
}

import { twoStepAction } from 'libjs-pdcore/actions';
import { createAction } from 'redux-actions';

export const startApplication = twoStepAction('start_application');

export const setFieldValue = createAction('set_field_value');
export const validateField = createAction('validate_field');
export const setError = createAction('set_error');

export const validateForm = createAction('validate_form');

export const initializeForm = createAction('initialize_form');

export const submitDemo = createAction('submit_demo');
export const setLaoding = createAction('set_loading');

export const setCountryCode = createAction('set_country_code');

export const setActiveLanguage = createAction('set_active_language');

import { FIELDS } from 'containers/Application/constants';

export const ROUTER_NAME = 'website_booker';
export const API_ROUTER = 'api_router';
export const WORKSPACE = 'pandadoc';

export const MAP_CHILIPIPER_FIELDS_ID = {
  [FIELDS.FIRST_NAME]: 'FirstName',
  [FIELDS.LAST_NAME]: 'LastName',
  [FIELDS.EMAIL]: 'Email',
  [FIELDS.PHONE]: 'Phone',
  [FIELDS.COMPANY_NAME]: 'Company',
  [FIELDS.COMPANY_SIZE]: 'CompanySize',
};

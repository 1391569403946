import styled from 'styled-components';
import { CommonInputStyles, FieldWrapper } from '../common';
import { CONTAINER_CLASS } from './constants';
import arrow from './images/arrow.svg';

export const Wrapper = styled(FieldWrapper)`
  .${CONTAINER_CLASS} {
    > input {
      ${CommonInputStyles}
      padding-left: 60px;
    }

    .arrow {
      border: none !important;
      background: url(${arrow});
      width: 14px;
      height: 14px;
      margin-top: -8px;
      left: 21px;
    }

    .selected-flag {
      background: #fff;
      border: none;
      width: 100%;
    }

    .flag-dropdown {
      border: none;
      width: 50px;
      height: 38px;
      margin-top: 1px;
      margin-left: 1px;
      border-right: 1px solid #dfdcda;
    }

    .flag-dropdown {
      font-family: Graphik, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
        Roboto, 'Helvetica Neue', Arial, sans-serif;
      font-size: 14px;
      position: absolute;
    }

    .country-list {
      width: 235px;
    }
  }
`;

import PropTypes from 'prop-types';
import * as Styled from './styled';

export const Step = ({ label, order }) => (
  <Styled.Step>
    <Styled.StepOrder>{order}</Styled.StepOrder>
    <Styled.StepLabel>{label}</Styled.StepLabel>
  </Styled.Step>
);

Step.propTypes = {
  label: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
};

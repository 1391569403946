import React from 'react';
import * as Styled from './styled';
import { Buttons } from './components/Buttons';
import { Step2 } from './components/Step2';
import { Step1 } from './components/Step1';
import { useSteps } from './hooks/useSteps';

/*eslint-disable i18next/no-literal-string */
export const DemoInfo = () => {
  const { step, setStep, offset, width, ref, pauseAutoScroll, resumeAutoScroll } =
    useSteps();

  return (
    <Styled.Wrapper onMouseEnter={pauseAutoScroll} onMouseLeave={resumeAutoScroll}>
      <Styled.StepsTrack ref={ref} width={width} offset={offset}>
        <Step1 />
        <Step2 />
      </Styled.StepsTrack>
      <Buttons setStep={setStep} step={step} maxStep={2} />
    </Styled.Wrapper>
  );
};

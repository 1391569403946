import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  margin-top: 100px;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 960px) {
    flex-direction: column;
    max-width: 490px;
    margin: 100px auto 0px;
    text-align: center;
  }

  @media (max-width: 600px) {
    margin: 60px auto 0px;
  }
`;

import PropTypes from 'prop-types';
import * as Styled from './styled';
import { Prev } from './Prev';
import { Next } from './Next';
import { useCallback } from 'react';

export const Buttons = ({ step, setStep, maxStep }) => {
  const prevDisabled = step === 1;
  const nextDisabled = step === maxStep;

  const handeNextClick = useCallback(() => {
    if (!nextDisabled) {
      setStep(step + 1);
    }
  }, [step, setStep]);

  const handePrevClick = useCallback(() => {
    if (!prevDisabled) {
      setStep(step - 1);
    }
  }, [step, setStep]);

  return (
    <Styled.Buttons>
      <Prev disabled={prevDisabled} onClick={handePrevClick} />
      <Next disabled={nextDisabled} onClick={handeNextClick} />
    </Styled.Buttons>
  );
};

Buttons.propTypes = {
  setStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
};

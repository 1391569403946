/* eslint-disable i18next/no-literal-string */
import styled, { css } from 'styled-components';

export const FieldWrapper = styled.div`
  height: auto;
  box-sizing: border-box;
  position: relative;
  background-color: transparent;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(50% - 5px)')};
  margin-bottom: 12px;

  ${({ theme }) => theme.media.down(theme.breakpoints.xsmall)} {
    width: 100%;
  }
`;

export const Label = styled.div`
  position: absolute;
  pointer-events: none;
  top: 12px;
  left: 15px;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  transition: all 0.1s ease-out;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 50px);
`;

export const ActiveLabel = styled(Label)`
  top: 5px;
  left: 15px;
  font-size: 12px;
  line-height: 18px;
`;

export const CommonInputStyles = css`
  outline: none;
  border-radius: 0;
  width: 100%;
  font-size: 14px;
  padding: 20px 10px 5px 15px;
  box-sizing: border-box;
  font-family: Graphik, -apple-system, system-ui, system-ui, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  height: 43px;
  border: 1px solid
    ${(props) => (props.error ? 'rgba(228, 78, 72, 0.5)' : 'rgba(36, 36, 36, 0.3)')};
  background-color: #ffffff;

  @media (hover: hover) {
    &:hover {
      border: 1px solid ${(props) => (props.error ? '#E44E48' : 'rgba(36, 36, 36, 0.6)')};
    }
  }

  &:focus {
    border: 1px solid ${(props) => (props.error ? '#E44E48' : '#47B972')};
  }

  &:focus + ${Label} {
    top: 5px;
    font-size: 12px;
    line-height: 18px;
  }

  &::-ms-reveal {
    display: none;
  }

  &::-ms-clear {
    display: none;
  }

  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
`;

export const FieldError = styled.div`
  width: 100%;
  font-size: 12px;
  color: #e44e48;
  margin-top: 3px;
`;

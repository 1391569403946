import styled from 'styled-components';
import { CommonInputStyles, FieldWrapper, ActiveLabel } from '../common';
import arrow from './images/arrow.svg';
import { CONTAINER_CLASS } from './constants';

export const PhoneActiveLabel = styled(ActiveLabel)`
  left: 66px;
`;

export const Wrapper = styled(FieldWrapper)`
  .${CONTAINER_CLASS} {
    > input {
      ${CommonInputStyles}
      padding: 20px 10px 5px 66px;
    }

    .flag-dropdown {
      border: none;
      top: 1px;
      left: 1px;
      bottom: 0;
      background-color: #fff;
      border-right: 1px solid rgba(36, 36, 36, 0.3);
      height: 41px;
      font-family: Graphik, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
        Roboto, 'Helvetica Neue', Arial, sans-serif;
      font-size: 14px;
    }

    .arrow {
      border: none !important;
      background: url(${arrow});
      width: 14px !important;
      height: 14px !important;
      margin-top: -6px !important;
      left: 21px;
    }

    .selected-flag {
      width: 50px;
      height: 100%;
      padding-left: 10px;
    }

    .country-list {
      width: 216px;

      @media (max-width: 680px) {
        width: 200px;
      }
    }
  }
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ background }) => background};
  width: 100%;
  min-height: 100vh;
`;

export const Layout = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  padding: 25px 60px;

  @media (max-width: 600px) {
    padding: 11px 20px 25px;
  }
`;

import React from 'react';
import { useSelector } from 'store';
import { useTranslation } from '@libjs-pdlocalization/components/i18n';
import { activeLanguageSelector } from 'containers/Application/selectors';
import * as Styled from './styled.js';

export const Footer = () => {
  const { t } = useTranslation();
  const year = 2023;

  const activeLanguage = useSelector(activeLanguageSelector);

  return (
    <Styled.Footer>
      <div>{t('© {{year}} PandaDoc Inc. All rights reserved.', { year })}</div>
      <div>
        <Styled.Link
          href={`https://www.pandadoc.com${activeLanguage.path}/privacy-notice`}
          target="_blank"
        >
          {t('Privacy policy')}
        </Styled.Link>
        <Styled.Link
          href={`https://www.pandadoc.com${activeLanguage.path}/terms-of-use`}
          target="_blank"
        >
          {t('Terms of use')}
        </Styled.Link>
        <Styled.Link
          href={`https://www.pandadoc.com${activeLanguage.path}/gdpr`}
          target="_blank"
        >
          {t('GDPR')}
        </Styled.Link>
      </div>
    </Styled.Footer>
  );
};
